const forms = {
  SAVE: 'Tallenna',
  SEND: 'Lähetä',
  CANCEL: 'Peruuta',
  DELETE: 'Poista',
  EDIT: 'Muokkaa',
  EMAIL: 'Sähköposti',
  PHONE: 'Puhelin',
  CODE: 'Koodi',
  ERROR_EMAIL_PHONE: 'Syötä sähköpostiosoite tai puhelinnumero.',
  ERROR_PHONE: 'Syötä puhelinnumero.'
}

export default forms
