import * as get from 'lodash/get'
import * as lowerCase from 'lodash/lowerCase'
import * as merge from 'lodash/merge'
import * as reduce from 'lodash/reduce'
import * as React from 'react'
import { vsprintf } from 'sprintf-js'
import contentfulClient from '../helpers/contentfulClient'
import { stripTags } from '../helpers/stripTags'
import content from './strings'

type Props = {
  text: string
  args: string[]
}

const cachedCmsContent = {}
const cmsRequestMade = false

function parseCMSContent(cmsResponse) {
  function sanitize(item) {
    // Look for content in the fields
    if ('fields' in item) {
      return sanitize(item.fields)
    }

    return item
  }

  const parsedContent = reduce(
    cmsResponse.items,
    (contentList, item) => {
      const contentItem = sanitize(item)
      contentList[lowerCase(contentItem.name)] = contentItem.content
      return contentList
    },
    {}
  )

  return parsedContent
}

// Commented out so that texts are only in one place. Keeping this for now, since we might move texts to Contentful once development is ready.
// function ensureCMSContent() {
//   if (!cmsRequestMade) {
//     cmsRequestMade = true

//     contentfulClient
//       .generalContent()
//       .then(parseCMSContent)
//       .then((parsedContent) => merge(cachedCmsContent, parsedContent))
//       .then((cached) => merge(content, cached))
//   }
// }

function findContent(textKey) {
  // ensureCMSContent()

  if (textKey.indexOf('.') !== -1) {
    return get(content, textKey, textKey)
  }

  return reduce(
    content,
    (defaultResult, namespace) => {
      const result = get(namespace, textKey, false)
      return result ? result : defaultResult
    },
    textKey
  )
}

export const getTextGroup = (groupName) => {
  return content[groupName]
}

export const Text = (propsOrText: string | Props, ...rest: string[]) => {
  const props: Props =
    typeof propsOrText === 'string' ? { text: propsOrText, args: rest } : propsOrText
  const { text: textKey = '', args = [] } = props

  let str =
    textKey.indexOf('.') !== -1
      ? get(content, textKey, textKey)
      : findContent(textKey)

  str = stripTags(str, '<a><span><p>')
  str = !args.length ? str : vsprintf(str, args)

  if (/<[a-z][\s\S]*>/i.test(str)) {
    return <span dangerouslySetInnerHTML={{ __html: str }} />
  }

  return str
}
