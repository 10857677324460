import * as React from 'react'
import * as css from './FlashMessage.css'

type Props = {
  message?: string
  style?: any
}

class FlashMessage extends React.Component<Props> {
  render() {
    const { message, style } = this.props

    return (
      <div className={css.messageContainer} style={style}>
        <span className={css.message}>{message}</span>
      </div>
    )
  }
}

export default FlashMessage
