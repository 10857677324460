import * as React from 'react'
import { Text } from '../content/text'

type Props = {
  style?: {}
}

export default ({ style }: Props) => (
  <p
    className="smallPrint"
    style={{
      textAlign: 'center',
      ...style
    }}>
    <Text text="CONFIRM_MESSAGE_NOT_ARRIVE_PRESS_CANCEL" />
  </p>
)
