import gql from 'graphql-tag'
import * as React from 'react'
import { mutate } from '../apollo/Mutation'
import FormActions from '../components/FormActions'
import Tooltip from '../components/Tooltip'
import { Text } from '../content/text'
import { translateError } from '../helpers/translateError'
import Field from '../forms/Field'
import Form from '../forms/Form'
import { FormProps } from '../types/FormProps'

const changePassword = gql`
  mutation changePassword(
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirm: String!
  ) {
    changePassword(
      oldPassword: $currentPassword
      newPassword: $newPassword
      newPasswordConfirm: $newPasswordConfirm
    ) {
      success
      errorCode
      errorMessage
    }
  }
`

type FormState = {
  currentPassword: string
  newPassword: string
  newPasswordConfirm: string
}

interface PasswordFormProps extends FormProps {
  username: string
}

@mutate(changePassword)
class ChangePasswordForm extends React.Component<PasswordFormProps, FormState> {
  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { mutator } = this.props
    const { currentPassword, newPassword, newPasswordConfirm } = this.state

    mutator({
      variables: {
        currentPassword,
        newPassword,
        newPasswordConfirm
      }
    })
  }

  render() {
    const {
      onEnable,
      enabled,
      mutationLoading,
      mutationError,
      onCancel,
      username
    } = this.props
    const { currentPassword, newPassword, newPasswordConfirm } = this.state

    const translatedError = translateError(mutationError)

    return (
      <Form onSubmit={this.onSubmit}>
        <input
          type="hidden"
          value={username}
          name="username"
          autoComplete="username"
        />
        <Field
          validationRules="required"
          onEditClick={onEnable}
          id="currentPassword"
          type="password"
          onChange={this.setFormState('currentPassword')}
          key={
            !enabled
              ? 'disabled_password'
              : 'enabled_password' /* Separate instances to kick validation into gear */
          }
          value={
            !enabled
              ? Math.random()
                  .toString(12)
                  .slice(2)
              : currentPassword || ''
          }
          disabled={!enabled}
          autoComplete="current-password"
          name="currentPassword"
          label={Text('CURRENT_PASSWORD')}
        />
        {enabled && (
          <>
            <Field
              validationRules="required|min:8"
              id="newPassword"
              type="password"
              onChange={this.setFormState('newPassword')}
              value={newPassword || ''}
              autoComplete="new-password"
              name="newPassword"
              label={
                <>
                  <Text text="NEW_PASSWORD" />
                  <Tooltip message={Text('AUTH_NEW_PASSWORD')} />
                </>
              }
            />
            <Field
              validationRules="required|same:newPassword"
              validationReference={{
                newPassword,
                currentPassword
              }}
              id="newPassword_confirmation"
              type="password"
              onChange={this.setFormState('newPasswordConfirm')}
              value={newPasswordConfirm || ''}
              autoComplete="new-password"
              name="newPasswordConfirm"
              label={Text('VERIFY_PASSWORD')}
            />
            <FormActions
              error={translatedError}
              loading={mutationLoading}
              onCancel={onCancel}
            />
          </>
        )}
      </Form>
    )
  }
}

export default ChangePasswordForm
