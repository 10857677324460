import * as React from 'react'
import * as css from './Title.css'

type TitleProps = {
  title?: string
  text?: string
  children?: React.ReactNode
}

export default ({ title, children = '', text = children }: TitleProps) => (
  <div className={css.title}>
    <h1>{title}</h1>
    {text && <p className={css.text}>{text}</p>}
  </div>
)
