import gql from 'graphql-tag'

export default gql`
  mutation login($username: String!, $password: String!, $from: From = null) {
    login(username: $username, password: $password, from: $from) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`
