import * as React from 'react'
import gql from 'graphql-tag'
import { mutate } from '../apollo/Mutation'
import { Text } from '../content/text'
import Form from './Form'
import Field from './Field'
import Loading from '../components/Loading'
import FormButtons from './FormButtons'
import Tooltip from '../components/Tooltip'
import TargetingDisclaimer from '../components/TargetingDisclaimer'
import { isEmail } from '../helpers/isEmail'
import { isPhone } from '../helpers/isPhone'
import { errorMsgStr } from '../helpers/graphql'
import * as css from '../styles/Form.css'

const claimLilyProfile = gql`
  mutation claimLilyProfile(
    $legacyUsername: String!
    $legacyPassword: String!
    $fullName: String
    $username: String!
    $password: String!
    $signUpState: String = null
  ) {
    claimLilyProfile(
      legacyUsername: $legacyUsername
      legacyPassword: $legacyPassword
      fullName: $fullName
      username: $username
      password: $password
      signUpState: $signUpState
    ) {
      userSub
      userConfirmed
      codeDeliveryDetails {
        attributeName
        destination
        deliveryMedium
      }
    }
  }
`
@mutate(claimLilyProfile)
class ClaimProfileWithNewUserForm extends React.Component<any, any> {
  componentDidUpdate(prevProps, prevState) {
    const { mutationError, onUserExistsError } = this.props

    if (mutationError) {
      const errorMsg = errorMsgStr(mutationError)

      if (errorMsg === 'UsernameExistsException') {
        onUserExistsError()
      }
    }
  }

  usernameType = () => {
    const { username } = this.props
    return isEmail(username) ? 'email' : isPhone(username) ? 'phoneNumber' : ''
  }

  sendRequest = (e) => {
    e.preventDefault()
    const {
      mutator,
      legacyUsername,
      legacyPassword,
      fullName,
      username,
      password,
      onChange,
      onClaimCompleted,
      signUpState
    } = this.props

    const usernameType = this.usernameType()
    onChange('usernameType')(usernameType)

    mutator({
      variables: {
        legacyUsername,
        legacyPassword,
        fullName,
        username,
        password,
        signUpState
      }
    }).then(({ data }) => {
      if (data.claimLilyProfile.codeDeliveryDetails.destination) {
        onClaimCompleted(data)
      }
    })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      onChange,
      legacyUsername,
      legacyPassword,
      fullName,
      username,
      password,
      confirmPassword,
      needsFullName
    } = this.props

    return (
      <Form className={css.form} onSubmit={this.sendRequest}>
        <input
          type="hidden"
          value={legacyUsername}
          name="legacyUsername"
          autoComplete="username"
        />
        <input
          type="hidden"
          value={legacyPassword}
          name="legacyPassword"
          autoComplete="password"
        />
        {needsFullName && (
          <Field
            validationRules="required|max:255"
            autoComplete="fullName"
            onChange={onChange('fullName')}
            value={fullName || ''}
            type="text"
            id="fullName"
            name="fullName"
            label={
              <>
                <Text text="Nimi" />
                <Tooltip message={Text('GIVE_NAME_SHOWN_TO_YOU')} />
              </>
            }
          />
        )}
        <Field
          validationRules="required|email_or_phone"
          autoComplete="username"
          onChange={onChange('username')}
          value={username || ''}
          type="email"
          id="email"
          name="email"
          label={
            <>
              <Text text="EMAIL_OR_PHONE" />
              <Tooltip message={Text('SEND_VERIFICATION_INFO')} />
            </>
          }
        />
        <Field
          validationRules="required|min:8"
          autoComplete="new-password"
          onChange={onChange('password')}
          value={password || ''}
          type="password"
          id="password"
          name="password"
          label={
            <>
              <Text text="PASSWORD" />
              <Tooltip message={Text('AUTH_NEW_PASSWORD')} />
            </>
          }
        />
        <Field
          validationRules="required|same:password"
          validationReference={{ password }}
          onChange={onChange('confirmPassword')}
          value={confirmPassword || ''}
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          label={Text('VERIFY_PASSWORD')}
        />
        <TargetingDisclaimer />
        <Loading
          error={mutationError}
          label={Text('CREATING')}
          loading={mutationLoading}
        />
        <FormButtons
          submitEnabled={
            !!username &&
            !!password &&
            !!confirmPassword &&
            (needsFullName ? !!fullName : true)
          }
          submitLabel={Text('CREATE_USER')}
        />
      </Form>
    )
  }
}

export default ClaimProfileWithNewUserForm
