// Stolen from Locutus

export const stripTags = (input: string, allowed: string = ''): string => {
  // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  const allowedTags = (
    ((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []
  ).join('')

  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi

  let after = input
  // recursively remove tags to ensure that the returned string doesn't contain forbidden tags after previous passes (e.g. '<<bait/>switch/>')
  while (true) {
    const before = after

    after = before.replace(commentsAndPhpTags, '').replace(tags, ($0, $1) => {
      return allowedTags.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : ''
    })

    // return once no more tags are removed
    if (before === after) {
      return after
    }
  }
}
