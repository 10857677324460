import * as get from 'lodash/get'
import * as React from 'react'
import Validator from 'validatorjs'
import validation from '../content/strings/validation'
import { getTextGroup } from '../content/text'
import { FieldProps } from '../forms/Field'
import * as ValidationContext from '../helpers/validationContext'
import { AnyFunction } from '../types/Function'
import { identity } from './identity'
import validatorTranslations from '../content/validator.fi'

interface Props extends FieldProps {
  validationRules?: string
  validateOnMount?: boolean
  onValidation?: AnyFunction
  validationName?: string
  name: string
  value?: string
  checked?: boolean
  onChange?: AnyFunction
  validationReference?: {}
  useIcon?: boolean // Required by radio button
  imageMap?: {} // Required by ImageGridField
}

type State = {
  errors: string[]
}

const validatedInput = (InputComponent) =>
  class ValidateInput extends React.Component<Props, State> {
    validator = null

    state = {
      errors: []
    }

    componentDidMount() {
      const {
        validationRules,
        validateOnMount = !!validationRules,
        value
      } = this.props

      if (validateOnMount) {
        this.validate(this.getValue(value, false))
      }
    }

    componentDidUpdate({ disabled: prevDisabled = false }) {
      const { disabled = false, value } = this.props

      if (prevDisabled && !disabled) {
        this.validate(this.getValue(value, false))
      }
    }

    createValidator = (value) => {
      const { name, validationRules, validationReference = {} } = this.props

      Validator.setMessages('fi', validatorTranslations)
      Validator.useLang('fi')

      return new Validator(
        { [name]: value, ...validationReference },
        { [name]: validationRules },
        getTextGroup('validation')
      )
    }

    validate = (value) => {
      const { onValidation = identity, validationRules = '', name } = this.props

      // Cannot validate without rules
      if (!validationRules) {
        return
      }

      this.validator = this.createValidator(value)

      const isValid = this.validator.passes()
      const errors = this.validator.errors.get(name)

      onValidation(isValid, errors)

      this.setState({
        errors
      })
    }

    getValue = (eventOrValue, flipChecked = true) => {
      const { checked } = this.props
      let val = get(eventOrValue, 'target.value', eventOrValue)

      if (typeof checked !== 'undefined') {
        // In this change event, the state is not yet updated so we must flip the checked status.
        const nextChecked = flipChecked ? !checked : checked
        val = !nextChecked ? false : val ? val : true
      }

      return val
    }

    onChange = (e) => {
      const { validationRules = '', onChange = identity } = this.props

      if (validationRules) {
        this.validate(this.getValue(e))
      }

      onChange(e)
    }

    render() {
      return (
        <InputComponent
          {...this.props}
          errors={this.state.errors}
          onChange={this.onChange}
        />
      )
    }
  }

const contextValidation = (Component) => (props: Props) => (
  <ValidationContext.Consumer>
    {({ onValidation: contextOnValidation, showErrors: contextShowErrors }) => {
      const {
        onValidation = contextOnValidation,
        showErrors = contextShowErrors,
        name = 'Undefined name',
        ...rest
      } = props

      return (
        <Component
          {...rest}
          name={name}
          onValidation={onValidation(name)}
          showErrors={showErrors}
        />
      )
    }}
  </ValidationContext.Consumer>
)

// Yo dawg I heard you like decorators, so I decorated your decorator.
export default (Component) => contextValidation(validatedInput(Component))
