import * as React from 'react'
import get from 'lodash/get'
import { Mutation } from 'react-apollo'
import classnames from 'classnames'
import { MdKeyboardArrowDown } from 'react-icons/lib/md'
import Loading from './Loading'
import FormButtons from '../forms/FormButtons'
import { Text } from '../content/text'
import * as css from './Service.css'

type Props = {
  name: string
  additionalText?: string
  deleteText?: string
  warningText?: string
  logo: any
  url?: string
  subtitle?: string
  mutationLabel?: string
  onProfileDeleteSuccess?: Function
  deleteProfileMutation?: any
}

type State = {
  showTextarea: boolean
  deleteSuccess: boolean
}

class Service extends React.Component<Props, State> {
  state: State = {
    showTextarea: false,
    deleteSuccess: false
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  toggleTextarea = (e) => {
    e.preventDefault()
    const showTextarea = !this.state.showTextarea
    this.setState({ showTextarea })
  }

  onMutationResult = (mutationResult) => {
    const { onProfileDeleteSuccess } = this.props
    if (mutationResult && !this.state.deleteSuccess) {
      this.setState({
        deleteSuccess: mutationResult ? mutationResult.success : false
      })
      if (onProfileDeleteSuccess) {
        setTimeout(() => {
          if (this._isMounted) {
            onProfileDeleteSuccess()
          }
        }, 2000)
      }
    }
  }

  onCancel = (e) => {
    e.preventDefault()
    this.setState({ showTextarea: false })
  }

  render() {
    const {
      name,
      logo,
      additionalText,
      deleteText,
      warningText,
      url,
      subtitle,
      deleteProfileMutation
    } = this.props

    const { showTextarea, deleteSuccess } = this.state

    const queryName = get(deleteProfileMutation, 'definitions[0].name.value')

    return (
      <div className={classnames(css.serviceItem, { [css.open]: showTextarea })}>
        <div className={css.serviceBar}>
          <img src={logo} className={css.logo} />
          <span className={css.name}>
            {url ? (
              <a href={url} target="_blank">
                {name}
              </a>
            ) : (
              name
            )}
            {subtitle && <div className={css.subtitle}>{subtitle}</div>}
          </span>
          {(additionalText || deleteText || deleteProfileMutation) && (
            <span className={css.arrowIcon} onClick={this.toggleTextarea}>
              <MdKeyboardArrowDown size={24} />
            </span>
          )}
        </div>
        {showTextarea && (
          <div className={css.textArea}>
            {additionalText && (
              <p className={css.additionalText}>
                <Text text={additionalText} />
              </p>
            )}
            {!deleteSuccess &&
              deleteText && (
                <p className={css.deleteText}>
                  <Text text={deleteText} />
                </p>
              )}
            {!deleteSuccess &&
              warningText && (
                <p className={css.warningText}>
                  <Text text={warningText} />
                </p>
              )}
            {deleteProfileMutation && (
              <Mutation
                mutation={deleteProfileMutation}
                onCompleted={(data) => {
                  const mutationResult = get(data, queryName, data)
                  if (mutationResult) {
                    this.onMutationResult(mutationResult)
                  }
                }}>
                {(deleteProfile, { loading, error, data }) => {
                  const mutationResult = get(data, queryName, data)

                  // result is of Result type { success, errorCode, errorMessage }
                  const mutationError =
                    mutationResult && mutationResult.errorCode
                      ? mutationResult.errorCode
                      : error

                  return (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        deleteProfile()
                      }}>
                      <Loading
                        loading={loading}
                        error={mutationError}
                        label={Text('DELETING_PROFILE')}
                        doneLabel={Text('PROFILE_DELETED')}
                        waitAfterDone={3000}
                      />
                      {!this.state.deleteSuccess && (
                        <FormButtons
                          submitEnabled={!loading}
                          cancelLabel={Text('CANCEL')}
                          submitLabel={Text('DELETE')}
                          onCancel={this.onCancel}
                        />
                      )}
                    </form>
                  )
                }}
              </Mutation>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Service
