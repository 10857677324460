import * as get from 'lodash/get'

type MaybeGraphQLError = {
  graphQLErrors?: Array<{
    message?: string
  }>
}

export function errorHasMessage(error: MaybeGraphQLError, message: string) {
  return !!getErrorWithMessage(error, message)
}

export function getErrorWithMessage(error: MaybeGraphQLError, message: string) {
  return (
    (error &&
      error.graphQLErrors &&
      error.graphQLErrors.find(
        (graphQLError) => graphQLError.message === message
      )) ||
    undefined
  )
}

export const errorMsgStr = (error) => {
  const message = get(error, 'message', error)
  const messageStr =
    typeof message === 'string' ? message.replace('GraphQL error: ', '') : ''
  return messageStr
}
