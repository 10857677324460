import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MdExitToApp } from 'react-icons/lib/md'
import { withRouter } from 'react-router-dom'
import { clearAuthTokens } from '../helpers/authentication'
import { routes } from '../routes'
import * as css from './LogoutLink.css'

export default withRouter(
  observer(({ history }) => (
    <a
      className={css.logoutLink}
      href="#"
      onClick={(e) => {
        e.preventDefault()
        clearAuthTokens()
        history.push(routes.home())
      }}>
      <span className={css.label}>Kirjaudu ulos</span>
      <MdExitToApp size={20} />
    </a>
  ))
)
