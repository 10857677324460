export function hash(str: string) {
  let hashResult = 0
  let i
  let chr

  if (str.length === 0) {
    return hashResult
  }

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    // tslint:disable-next-line
    hashResult = (hashResult << 5) - hashResult + chr
    // tslint:disable-next-line
    hashResult |= 0 // Convert to 32bit integer
  }

  return hashResult
}
