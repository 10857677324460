import gql from 'graphql-tag'
import * as get from 'lodash/get'
import { stringify as stringifyQueryString } from 'qs'
import * as React from 'react'

import Field from '../forms/Field'
import Form from '../forms/Form'
import FormButtons from '../forms/FormButtons'
import Loading from '../components/Loading'
import { MutateProps } from '../types/MutateProps'
import { mutate } from '../apollo/Mutation'
import { routes } from '../routes'
import { Text } from '../content/text'
import { translateError } from '../helpers/translateError'

interface Props extends MutateProps {
  subId: String
  code: String
}

type State = {
  confirmSuccess: Boolean
  password: string
  confirmPassword: string
}

const ConfirmForgotPasswordBySubIdMutation = gql`
  mutation confirmForgotPasswordBySubId(
    $subId: String!
    $code: String!
    $password: String!
  ) {
    confirmForgotPasswordBySubId(subId: $subId, code: $code, password: $password) {
      success
      errorCode
      errorMessage
      state
    }
  }
`

@mutate(ConfirmForgotPasswordBySubIdMutation)
class ConfirmUserWithLink extends React.Component<Props, State> {
  state = {
    confirmSuccess: false,
    password: '',
    confirmPassword: ''
  }

  onSubmit = () => {
    const { history, mutator, subId, code } = this.props
    const { password } = this.state
    mutator({
      variables: {
        subId,
        code,
        password
      }
    }).then((result) => {
      if (get(result, 'data.confirmForgotPasswordBySubId.success', false) === true) {
        const stateStr = get(result, 'data.confirmForgotPasswordBySubId.state')
        let qs = ''
        if (stateStr) {
          try {
            const stateObj = JSON.parse(stateStr)
            qs = '?' + stringifyQueryString(stateObj)
          } catch (err) {
            console.error(err)
          }
        }

        history.push(`${routes.home()}${qs}`)
      }
    })
  }

  render() {
    const { mutationError, mutationLoading } = this.props
    const { password, confirmPassword } = this.state
    const translatedError = translateError(mutationError)
    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          validationRules="required|min:8"
          autoComplete="new-password"
          onChange={({ target: { value } }) => {
            this.setState({ password: value })
          }}
          value={password}
          type="password"
          id="password"
          name="password"
          label={Text('NEW_PASSWORD')}
        />
        <Field
          validationRules="required|same:password"
          validationReference={{ password }}
          onChange={({ target: { value } }) => {
            this.setState({ confirmPassword: value })
          }}
          value={confirmPassword}
          type="password"
          name="confirmPassword"
          label={Text('VERIFY_NEW_PASSWORD')}
        />
        <Loading
          loading={mutationLoading}
          label={Text('CONFIRM_ACCOUNT_LOADING')}
          doneLabel={Text('CONFIRM_ACCOUNT_SUCCESS')}
          error={translatedError}
        />
        <FormButtons
          submitEnabled={!!password && !!confirmPassword}
          cancelRoute={routes.home()}
          submitLabel={Text('SEND')}
        />
      </Form>
    )
  }
}

export default ConfirmUserWithLink
