import * as React from 'react'
import qs from 'qs'

import Logo from '../components/Logo'
import Footer from '../components/Footer'
import LoginForm from '../forms/LoginForm'
import Title from '../components/Title'
import { Text } from '../content/text'
import Layout from '../layout/Layout'
import { clearAuthTokens } from '../helpers/authentication'

class Login extends React.Component<any, any> {
  componentDidMount() {
    clearAuthTokens()
  }

  render() {
    const webView =
      qs.parse(location.search, { ignoreQueryPrefix: true }).webview === 'true'

    return (
      <Layout main>
        {!webView && <Logo />}
        <Layout constrained centered withTopPadding={webView}>
          <Title title={Text('AUTH_TITLE')} text={Text('AUTH_INGRESS')} />
          <LoginForm />
        </Layout>
        {!webView && <Footer />}
      </Layout>
    )
  }
}

export default Login
