import * as React from 'react'
import { NavLink } from 'react-router-dom'

// Link is used to get possible url query parameters to be passed on to links
type Props = {
  path: string
  children: any
}

export default ({ path, ...rest }: Props) => {
  const fullPath = path + (window.location.search || '')

  return <NavLink to={fullPath} {...rest} />
}
