export default {
  accepted: 'The :attribute must be accepted.',
  after: ':attribute pitää olla :after jälkeen.',
  after_or_equal: ':attribute pitää olla sama kuin :after_or_equal tai sen jälkeen.',
  alpha: ':attribute kenttä saa sisältää vain kirjaimia.',
  alpha_dash: ':attribute saa sisältää vain kirjaimia, väliviivoja tai alaviivoja',
  alpha_num: ':attribute saa sisältää vain kirjaimia tai numeroita',
  before: ':attribute pitää olla ennen :before.',
  before_or_equal: 'The :attribute must be equal or before :before_or_equal.',
  between: 'The :attribute field must be between :min and :max.',
  confirmed: 'The :attribute confirmation does not match.',
  email: ':attribute ei tunnisteta sähköpostiosoitteeksi.',
  date: 'The :attribute is not a valid date format.',
  def: 'Tarkista kenttä',
  digits: ':attributen pitää olla :digits numeroa pitkä.',
  different: 'The :attribute and :different must be different.',
  in: 'The selected :attribute is invalid.',
  integer: 'The :attribute must be an integer.',
  min: {
    numeric: 'The :attribute must be at least :min.',
    string: 'The :attribute must be at least :min characters.'
  },
  max: {
    numeric: 'The :attribute may not be greater than :max.',
    string: 'The :attribute may not be greater than :max characters.'
  },
  not_in: 'The selected :attribute is invalid.',
  numeric: 'The :attribute must be a number.',
  present: 'The :attribute field must be present (but can be empty).',
  required: 'The :attribute field is required.',
  required_if: 'The :attribute field is required when :other is :value.',
  required_unless: 'The :attribute field is required when :other is not :value.',
  required_with: 'The :attribute field is required when :field is not empty.',
  required_with_all: 'The :attribute field is required when :fields are not empty.',
  required_without: 'The :attribute field is required when :field is empty.',
  required_without_all: 'The :attribute field is required when :fields are empty.',
  same: 'Uusi salasana ja salasanan vahvistus eivät täsmää.',
  size: {
    numeric: 'The :attribute must be :size.',
    string: 'The :attribute must be :size characters.'
  },
  string: 'The :attribute must be a string.',
  url: 'The :attribute format is invalid.',
  regex: 'The :attribute format is invalid.',
  attributes: {}
}
