import gql from 'graphql-tag'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import { Text } from '../content/text'
import Layout from '../layout/Layout'
import Loading from './Loading'

const resendCodeMutation = gql`
  mutation resendConfirmationCode($username: String!) {
    resendConfirmationCode(username: $username) {
      destination
    }
  }
`

type Props = {
  username: string
  mutation?: any
  style?: {}
}

class ResendConfirmationCode extends React.Component<Props, any> {
  render() {
    const { username, mutation = resendCodeMutation, style } = this.props

    return (
      <div
        className="smallPrint"
        style={{
          margin: '0 0 var(--margin)',
          textAlign: 'center',
          ...style
        }}>
        <Text text="CONFIRM_MESSAGE_NOT_ARRIVE" />,{' '}
        <Mutation mutation={mutation}>
          {(resendCode, { loading: resendLoading }) => (
            <>
              <a
                className={resendLoading ? 'disabled' : ''}
                href="#"
                style={{ whiteSpace: 'nowrap' }}
                onClick={(e) => {
                  e.preventDefault()
                  resendCode({
                    variables: { username }
                  })
                }}>
                <Text text="CLICK_HERE" />.
              </a>{' '}
              <Loading
                inline
                label={Text('SENDING')}
                doneLabel={Text('SENT')}
                loading={resendLoading}
              />
            </>
          )}
        </Mutation>
      </div>
    )
  }
}

export default ResendConfirmationCode
