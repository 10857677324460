const validation = {
  required: 'Täytä tämä kenttä.',
  email: 'Tarkista tämä kenttä.',
  phone: 'Tarkista tämä kenttä.',
  min: {
    numeric: 'Tämän kentän alin arvo on :min.',
    string: 'Tämän pitää olla ainakin :min merkkiä pitkä.'
  },
  max: {
    numeric: 'Tämän kentän suurin sallittu arvo on :max.',
    string: 'Tämä saa olla korkeintaan :max merkkiä pitkä.'
  },
  alpha: 'Tämä saa sisältää vain kirjaimia.',
  alpha_dash: 'Tämä saa sisältää kirjaimia, numeroita sekä viivoja.',
  alpha_num: 'Tämä saa sisältää vain kirjaimia ja numeroita.',
  date: 'Tarkista päiväys.',
  accepted: 'Tämän pitää olla valittu.',
  age_confirmation_required:
    'Mukavaa, että haluaisit luoda tunnuksen palveluumme. Valitettavasti et voi saada tunnusta käyttöön ennen kuin olet täyttänyt 13 vuotta. Toivottavasti nähdään silloin!'
}

export default validation
