import * as get from 'lodash/get'
import * as isMatch from 'lodash/isMatch'
import * as reduce from 'lodash/reduce'

export default (nextProps, state) => {
  if (nextProps.mutationLoading) {
    return null
  }

  const prevProps = get(state, '_prevProps', false)

  if (prevProps && isMatch(nextProps, prevProps)) {
    return null
  }

  const nextState = reduce(
    state,
    (returnState, value, prop) => {
      if (typeof nextProps[prop] !== 'undefined') {
        return { ...returnState, [prop]: nextProps[prop] }
      }

      return returnState
    },
    state
  )

  nextState._prevProps = nextProps

  return nextState
}
