export const offset = (element) => {
  let box

  try {
    box = element.getBoundingClientRect()
  } catch (e) {
    // Ignore
  }

  const doc = element.ownerDocument
  const docElem = doc.documentElement

  const body = doc.body
  const win = window
  const clientTop = docElem.clientTop || body.clientTop || 0
  const clientLeft = docElem.clientLeft || body.clientLeft || 0
  const scrollTop = win.pageYOffset || docElem.scrollTop || body.scrollTop
  const scrollLeft = win.pageXOffset || docElem.scrollLeft || body.scrollLeft
  const top = box.top + scrollTop - clientTop
  const left = box.left + box.width / 2 + scrollLeft - clientLeft

  return { top, left }
}
