import classnames from 'classnames'
import * as React from 'react'
import { MdInfo } from 'react-icons/lib/md'
import { offset } from '../helpers/elementOffset'
import * as css from './Tooltip.css'

const enum MessageState {
  open = 'open',
  closed = 'closed'
}

type Props = {
  message: string
  hideIcon?: boolean
  messageClass?: string
  anchorRef?: Element
}

type State = {
  messageState: MessageState
}

class Tooltip extends React.Component<Props, State> {
  state = {
    messageState: MessageState.closed
  }

  iconRef: any = React.createRef()
  messageRef: any = React.createRef()

  onClick = (e) => {
    e.preventDefault()
    const { messageState } = this.state

    this.setState({
      messageState:
        messageState === MessageState.closed
          ? MessageState.open
          : MessageState.closed
    })
  }

  componentDidMount() {
    const anchorRef = this.iconRef.current
    const centerOn = anchorRef
      ? offset(anchorRef).left
      : document.documentElement.offsetWidth / 2

    const leftOffset = Math.round(centerOn)
    const messageRef = this.messageRef.current

    if (messageRef) {
      this.messageRef.current.style.setProperty(
        '--tooltip-offset',
        leftOffset + 'px'
      )
    }
  }

  render() {
    const { message = '', hideIcon = false, messageClass } = this.props
    const { messageState } = this.state

    return (
      <button type="button" className={css.infoButton} onMouseDown={this.onClick}>
        {!hideIcon && (
          <span ref={this.iconRef} className={css.iconWrapper}>
            <MdInfo size={18} className={css.icon} />
          </span>
        )}
        {message && (
          <div
            ref={this.messageRef}
            className={classnames(
              css.tooltipMessage,
              messageClass,
              messageState === MessageState.open ? css.open : ''
            )}>
            <p>{message}</p>
          </div>
        )}
      </button>
    )
  }
}

export default Tooltip
