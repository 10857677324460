import gql from 'graphql-tag'
import * as React from 'react'
import { mutate } from '../apollo/Mutation'
import Loading from '../components/Loading'
import { Text } from '../content/text'
import Field from '../forms/Field'
import Form from '../forms/Form'
import FormButtons from '../forms/FormButtons'
import { routes } from '../routes'
import * as css from '../styles/Form.css'
import { FormProps } from '../types/FormProps'
import { getQueryStringParams } from '../helpers/urlQueryString'

interface Props extends FormProps {
  username: string
  onChange: Function
}

const forgotPassword = gql`
  mutation forgotPassword($username: String!, $state: String = null) {
    forgotPassword(username: $username, state: $state) {
      destination
      deliveryMedium
      attributeName
    }
  }
`

@mutate(forgotPassword)
class ForgotPasswordForm extends React.Component<Props, any> {
  sendRequest = (e) => {
    e.preventDefault()
    const { mutator, username } = this.props

    const state = JSON.stringify(getQueryStringParams())

    mutator({
      variables: { username, state }
    })
  }

  render() {
    const { mutationLoading, mutationError, username, onChange } = this.props
    const homePathWithQuery = `${routes.home()}${window.location.search || ''}`

    return (
      <Form className={css.form} onSubmit={this.sendRequest}>
        <Field
          validationRules="required|email_or_phone"
          autoComplete="username"
          onChange={onChange('username')}
          value={username}
          type="email"
          id="email"
          name="email"
          label={Text('EMAIL_OR_PHONE')}
        />
        <Loading
          error={mutationError}
          label={Text('SENDING_REQUEST')}
          loading={mutationLoading}
        />
        <FormButtons
          submitEnabled={!!username}
          cancelRoute={homePathWithQuery}
          submitLabel={Text('SEND')}
        />
      </Form>
    )
  }
}

export default ForgotPasswordForm
