import * as React from 'react'
import * as css from './Accordion.css'
import { FaChevronDown } from 'react-icons/lib/fa'

interface AccordionProps {
  title: string
  content: React.ReactNode
}

interface AccordionState {
  isOpen: boolean
}

class Accordion extends React.Component<AccordionProps, AccordionState> {
  constructor(props: AccordionProps) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleAccordion = (): void => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }))
  }

  render() {
    const { title, content } = this.props
    const { isOpen } = this.state

    return (
      <div className={css.accordion}>
        <div className={css.accordionHeader} onClick={this.toggleAccordion}>
          <h5 className={css.title}>{title}</h5>
          <FaChevronDown
            className={isOpen ? css.accordionOpenIcon : css.accordionClosedIcon}
            size={15}
          />
        </div>
        {isOpen && <div className={css.accordionContent}>{content}</div>}
      </div>
    )
  }
}

export default Accordion
