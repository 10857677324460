/**
 * This file contains the custom <Mutation> component and the @mutate
 * decorator that makes working with Apollo mutations a bit smoother.
 *
 * <Mutation> works very similarly to the custom <Query> component, but
 * delegates loading and error handling to the wrapped component. Crucially,
 * it forwards the `onCompleted` prop to Apollo's <Mutation> so that the
 * parent can define what happens after the mutation is completed as is
 * often the best way to handle it. As a shortcut, the parent can also
 * set a `completedRoute` prop on the <Mutation> and it will redirect the
 * UI to the provided route when completed.
 *
 * In the wrapped component, use the function passed through the `mutator`
 * prop to perform the mutation. Use `mutationLoading` to display a
 * "loading" state in the component.
 *
 * The decorator applies a mutation in the same way but as a higher-order
 * component.
 */

import get from 'lodash/get'
import * as React from 'react'
import { Mutation as ApolloMutation } from 'react-apollo'
import { RouteComponentProps, withRouter } from 'react-router'
import { AnyFunction } from '../types/Function'
import { handleAuthError } from './handleAuthError'
import { routes } from '../routes'
import ForceLogout from '../helpers/ForceLogout'

interface Props extends RouteComponentProps<any> {
  completedRoute?: string
  onCompleted?: any
  mutation: any
  update?: AnyFunction
  component: any
}

// noinspection TsLint
export const Mutation = withRouter(
  ({
    mutation,
    update,
    component: Component,
    history = null,
    location,
    completedRoute = '',
    onCompleted = () => {
      if (history && completedRoute) {
        history.push(completedRoute)
      }
    },
    ...rest
  }: Props) => {
    const queryName = get(mutation, 'definitions[0].name.value')

    return (
      <ApolloMutation onCompleted={onCompleted} mutation={mutation} update={update}>
        {(mutatorFunction, { loading, error, data }) => {
          handleAuthError(error)

          const mutationResult = get(data, queryName, data)

          // result is of Result type { success, errorCode, errorMessage }
          const mutationError =
            mutationResult && mutationResult.errorCode
              ? mutationResult.errorCode
              : error

          return (
            <Component
              onCompleted={onCompleted}
              mutationLoading={loading}
              mutationError={mutationError}
              mutationResult={mutationResult}
              mutator={mutatorFunction}
              history={history}
              {...rest}
            />
          )
        }}
      </ApolloMutation>
    )
  }
)

export const mutate = (staticMutation, staticUpdate?): Function => (Component) => ({
  mutation = staticMutation,
  update = staticUpdate,
  ...rest
}: {
  update?: AnyFunction
  mutation: any
}) => (
  <Mutation mutation={mutation} update={update} component={Component} {...rest} />
)
