import classnames from 'classnames'
import * as invoke from 'lodash/invoke'
import * as padEnd from 'lodash/padEnd'
import * as React from 'react'
import { AnyFunction } from '../types/Function'
import * as css from './Input.css'

type Props = {
  value?: string
  onChange?: AnyFunction
  className?: string
  name?: string
  id?: string
  style?: any
}

class CodeInput extends React.Component<Props, any> {
  inputs = []
  focusedInput = 0

  setValue = (index: number, value: string): string => {
    const currentVal = this.getValue()

    const splitVal = currentVal.split('')
    splitVal.splice(index, 1, value.trim())

    return splitVal
      .join('')
      .trim()
      .substr(0, 6)
  }

  getValue = (): string => {
    return padEnd(this.props.value, 6).substr(0, 6)
  }

  onChange = (index) => (e) => {
    const { onChange } = this.props
    onChange(this.setValue(index, e.target.value))
  }

  setRef = (index) => (ref) => {
    if (index >= this.inputs.length) {
      // If the index is out of bounds, push to the array
      this.inputs.push(ref)
    } else {
      // Else, replace the ref in the array.
      this.inputs.splice(index, 1, ref)
    }
  }

  autoFocus = (focusOn = -1) => {
    const focusIndex = focusOn === -1 ? this.firstUnsetIndex() : focusOn
    // Safely call the focus method
    invoke(this, `inputs[${focusIndex}].focus`)

    if (focusIndex > -1 && focusIndex <= this.inputs.length - 1) {
      this.inputs[focusIndex].select()
      this.focusedInput = focusIndex
    }
  }

  firstUnsetIndex = () => {
    const value = this.getValue()
    const idx = value.indexOf(' ')
    return idx === -1 ? value.trim().length : idx
  }

  checkKeyCode = (e) => {
    if (e.shiftKey) {
      return
    }

    if (e.keyCode === 8) {
      // Backspace
      this.autoFocus(this.firstUnsetIndex() - 1)
    }

    if (e.keyCode === 37) {
      // Left
      this.autoFocus(this.focusedInput - 1)
    }

    if (e.keyCode === 39) {
      // Right
      this.autoFocus(this.focusedInput + 1)
    }
  }

  componentDidUpdate() {
    this.autoFocus()
  }

  componentDidMount() {
    this.autoFocus()
  }

  render() {
    const value = this.getValue()
    const { name = 'code_input', id = 'code_input', className, style } = this.props

    return (
      <div
        id={id}
        className={classnames(css.codeInputWrapper, className)}
        style={style}>
        {value
          .split('')
          .map((valueChar, idx) => (
            <input
              onKeyDown={this.checkKeyCode}
              autoComplete="off"
              autoCorrect="none"
              className={css.codeInput}
              ref={this.setRef(idx)}
              onChange={this.onChange(idx)}
              name={`${name}_${idx}`}
              key={`${id}_${idx}`}
              value={valueChar && valueChar !== ' ' ? valueChar : ''}
              type="number"
            />
          ))}
      </div>
    )
  }
}

export default CodeInput
