import classnames from 'classnames'
import * as get from 'lodash/get'
import * as React from 'react'
import * as css from './Layout.css'

const DEFAULT_BG = 'var(--silver)'

export default ({
  main = false,
  constrained = false,
  centered = false,
  white = false,
  row = false,
  noMargin = false,
  background,
  className,
  style,
  id,
  children,
  withTopPadding = false
}: {
  main?: boolean
  constrained?: boolean
  centered?: boolean
  row?: boolean
  white?: boolean
  noMargin?: boolean
  background?: { url?: string; color?: string } | string
  className?: string
  style?: any
  id?: string
  children: any
  withTopPadding?: boolean
}) => {
  const classes = classnames({
    [css.main]: main,
    [css.constrained]: constrained,
    [css.centeredLayout]: centered,
    [css.centeredRow]: row,
    [css.whiteBox]: white,
    [css.withTopPadding]: withTopPadding
  })

  const backgroundImage = get(background, 'url', '')
  const backgroundColor = get(background, 'color', background)

  const hasBackground = backgroundImage || backgroundColor

  return (
    <div
      className={`${css.backgroundContainer} ${
        hasBackground ? css.background : ''
      }`}>
      {hasBackground && (
        <div
          style={{ backgroundImage: `url("${backgroundImage}")`, backgroundColor }}
        />
      )}
      <div id={id} className={classes} style={style}>
        {children}
      </div>
    </div>
  )
}
