import gql from 'graphql-tag'
import * as snakeCase from 'lodash/snakeCase'
import * as React from 'react'
import { mutate } from '../apollo/Mutation'
import FormActions from '../components/FormActions'
import Tooltip from '../components/Tooltip'
import { Text } from '../content/text'
import Field from '../forms/Field'
import Form from '../forms/Form'
import initializeFormState from '../helpers/initializeFormState'
import { CredentialsFormOptions } from '../pages/ManageCredentials'
import { FormProps } from '../types/FormProps'
import { AnyFunction } from '../types/Function'
import ChangePasswordForm from './ChangePasswordForm'
import { MutateProps } from '../types/MutateProps'

type FormState = {
  phoneNumber: string
  email: string
}

interface Props extends FormProps {
  phoneNumber: string
  email: string
  onEditCompleted: AnyFunction // Do not use onCompleted because the mutation will call that
  enabledField: CredentialsFormOptions
}

const updateUserAttributes = gql`
  mutation updateUserAttributes($email: String = "", $phoneNumber: String = "") {
    updateUserAttributes(email: $email, phoneNumber: $phoneNumber) {
      attributeName
      deliveryMedium
      destination
    }
  }
`
type MutateVariables = {
  phoneNumber?: string
  email?: string
}

@mutate(updateUserAttributes)
class EditCredentialsForm extends React.Component<Props, FormState> {
  static defaultProps = {
    phoneNumber: '',
    email: ''
  }

  static getDerivedStateFromProps = initializeFormState

  state = {
    phoneNumber: '',
    email: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { mutator, onEditCompleted, enabledField } = this.props
    const { email, phoneNumber } = this.state
    const variables: MutateVariables = {}
    if (email) {
      variables.email = email
    }
    if (phoneNumber) {
      variables.phoneNumber = phoneNumber
    }

    mutator({ variables }).then(({ data }) => {
      let changedAttributeName = null
      let changedAttributeValue = ''

      if (data.updateUserAttributes) {
        changedAttributeName =
          enabledField === CredentialsFormOptions.email ? 'email' : 'phoneNumber'
        changedAttributeValue =
          changedAttributeName === 'email' ? email : phoneNumber
      }

      onEditCompleted(snakeCase(changedAttributeName))(changedAttributeValue)
    })
  }

  render() {
    const {
      enabledField,
      onCancel,
      onEnable,
      mutationError,
      mutationLoading
    } = this.props

    const { phoneNumber, email } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          validationRules="email"
          onEditClick={() => onEnable(CredentialsFormOptions.email)}
          type="text"
          onChange={this.setFormState('email')}
          value={email || ''}
          id="email"
          autoComplete="username email"
          disabled={enabledField !== CredentialsFormOptions.email}
          name="user_email"
          label={
            <>
              <Text text="EMAIL" />
              <Tooltip message={Text('AUTH_EMAIL')} />
            </>
          }
        />
        <FormActions
          error={mutationError}
          loading={mutationLoading}
          onCancel={onCancel}
          show={enabledField === CredentialsFormOptions.email}
        />
        <Field
          validationRules="phone"
          onEditClick={() => onEnable(CredentialsFormOptions.phone)}
          type="text"
          onChange={this.setFormState('phoneNumber')}
          value={phoneNumber || ''}
          id="phoneNumber"
          disabled={enabledField !== CredentialsFormOptions.phone}
          autoComplete="username tel"
          name="phoneNumber"
          label={
            <>
              <Text text="PHONE" />
              <Tooltip message={Text('AUTH_PHONE')} />
            </>
          }
        />
        <FormActions
          error={mutationError}
          loading={mutationLoading}
          onCancel={onCancel}
          show={enabledField === CredentialsFormOptions.phone}
        />
      </Form>
    )
  }
}

export default EditCredentialsForm
