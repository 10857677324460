import * as React from 'react'
import * as css from '../pages/footer.css'
import logo from '../img/a-lehdet_logo_512x512.png'

export default () => (
  <footer className={css.footer}>
    <img className={css.logoImg} src={logo} />
    <div className={css.footer__links}>
      <p className={css.link}>© A-lehdet Oy 1933–2023</p>
      <a
        href="https://www.a-lehdet.fi/tietoa-meista/kayttoehdot"
        target="_blank"
        className={css.link}>
        Käyttöehdot
      </a>
      <a
        href="https://www.a-lehdet.fi/tietoa-meista/tietosuojaseloste"
        target="_blank"
        className={css.link}>
        Tietosuojaseloste
      </a>
      <a
        href="https://www.a-lehdet.fi/tietoa-meista/evastekaytannot"
        target="_blank"
        className={css.link}>
        Evästekäytännöt
      </a>
    </div>
  </footer>
)
