import gql from 'graphql-tag'
import * as get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import qs from 'qs'
import * as React from 'react'
import classnames from 'classnames'
import { FaEye, FaEyeSlash } from 'react-icons/lib/fa'

import { mutate } from '../apollo/Mutation'
import Loading from '../components/Loading'
import NewUser from '../components/NewUser'
import { Text } from '../content/text'
import Field from '../forms/Field'
import Form from '../forms/Form'
import FormButtons from '../forms/FormButtons'
import ServiceLogos from '../components/ServiceLogos'
import authenticateUser from '../helpers/authenticateUser'
import { setAuthTokens } from '../helpers/authentication'
import setSSOIDCookie from '../helpers/setSsoidCookie'
import { urlQueryStringRedirect } from '../helpers/urlQueryString'
import { identity } from '../helpers/identity'
import { routes } from '../routes'
import * as css from '../styles/Form.css'
import { FormProps } from '../types/FormProps'
import { formatDateAndTime } from '../helpers/dates'
import { getErrorWithMessage } from '../helpers/graphql'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'
import Accordion from '../components/Accordion/Accordion'

type LoginFormState = {
  username: string
  password: string
  passwordVisible: boolean
}

interface LoginFormProps extends FormProps, WithApolloClient<{}> {
  onLoginError?: Function
  onLoginSuccess?: Function
  fieldClass?: string
  passwordLink?: boolean
  newUserLink?: boolean
  services?: boolean
  loginCompleted?: Function
  withRedirect?: boolean
}

@mutate(authenticateUser)
@observer
class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  static defaultProps = {
    passwordLink: true,
    newUserLink: true,
    services: true,
    withRedirect: true,
    onLoginSuccess: identity
  }

  state: LoginFormState = {
    username: '',
    password: '',
    passwordVisible: false
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  onLoggedIn = () => {
    const {
      mutationResult,
      onLoginSuccess,
      history,
      loginCompleted,
      withRedirect,
      client
    }: LoginFormProps = this.props

    setAuthTokens(mutationResult)
    onLoginSuccess(mutationResult)
    setSSOIDCookie(client)

    if (loginCompleted) {
      loginCompleted()
    } else if (withRedirect) {
      urlQueryStringRedirect(mutationResult.refreshToken, history)
    }
  }

  componentDidUpdate() {
    const { mutationResult, mutationError } = this.props

    if (mutationResult && !mutationError) {
      this.onLoggedIn()
    }
  }

  render() {
    const { username, password, passwordVisible } = this.state
    const {
      mutator,
      mutationResult,
      mutationLoading,
      mutationError,
      fieldClass,
      passwordLink,
      newUserLink,
      services
    } = this.props

    let bannedError
    let bannedUntil
    if (mutationError) {
      bannedError = getErrorWithMessage(mutationError, 'BannedException')
      bannedUntil =
        (bannedError &&
          bannedError.extensions &&
          bannedError.extensions.bannedUntil) ||
        undefined
    }

    const webView =
      qs.parse(location.search, { ignoreQueryPrefix: true }).webview === 'true'

    const fromSite = qs.parse(location.search, { ignoreQueryPrefix: true }).from
    let loginFrom
    switch (fromSite) {
      case 'apu':
        loginFrom = 'Apu'
        break
      case 'lily':
        loginFrom = 'Lily'
        break
    }

    const forgotPasswordUrl = `${routes.forgotPassword()}${window.location.search ||
      ''}`

    return (
      <Form
        className={css.form}
        onSubmit={(e) => {
          e.preventDefault()
          mutator({ variables: { username, password, from: loginFrom } })
        }}>
        <Field
          validationRules="required"
          className={classnames(css.usernameField, fieldClass)}
          label={Text('EMAIL_OR_PHONE')}
          autoComplete="username"
          type="text"
          name="username"
          id="username"
          onChange={this.setFormState('username')}
          value={username}
        />
        <div className={css.passwordInputContainer}>
          {password.length > 0 && (
            <a
              className={css.showPasswordButton}
              onClick={() => {
                this.setState({
                  passwordVisible: !passwordVisible
                })
              }}>
              {passwordVisible ? <FaEyeSlash size={15} /> : <FaEye size={15} />}
            </a>
          )}
          <Field
            validationRules="required"
            className={fieldClass}
            label={Text('PASSWORD')}
            labelRight={passwordLink ? Text('FORGOT_PASSWORD') : null}
            labelRightRoute={passwordLink ? forgotPasswordUrl : null}
            autoComplete="current-password"
            onChange={this.setFormState('password')}
            value={password}
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            id="password"
            errorRight
          />
        </div>
        <Loading
          error={mutationError}
          label={Text('LOGGING_IN')}
          doneLabel={Text('LOGGED_IN')}
          // when in web view, continue showing spinner as data is being loaded in the background
          loading={mutationLoading || (webView && !!mutationResult)}
        />
        {bannedUntil && (
          <p className={css.errorRed}>
            Voit kirjautua seuraavan kerran{' '}
            {formatDateAndTime(bannedError.extensions.bannedUntil)}.
          </p>
        )}
        <FormButtons
          submitEnabled={!!username && !!password && !mutationResult}
          className={css.buttonRow}
          submitLabel={Text('LOGIN')}
        />
        {newUserLink && <NewUser />}
        {!webView && (
          <Accordion
            title="Mikä on A-tunnus?"
            content={
              <p>
                A-tunnuksesi on käytössä alla olevissa palveluissamme. Voit poistaa
                profiilisi yksittäisistä palveluista tai halutessasi kaikista
                A-lehtien palveluista poistamalla koko käyttäjätunnuksesi.
              </p>
            }
          />
        )}
        {services && !webView && <ServiceLogos />}
      </Form>
    )
  }
}

export default withApollo<any>(LoginForm)
