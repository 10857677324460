import gql from 'graphql-tag'
import cookies from 'js-cookie'

export const SSO_ID_STORAGE_KEY = 'ssoid'
export const SSO_ID_DATE_STORAGE_KEY = 'ssoid_date'
export const COOKIE_DOMAIN = 'a-lehdet.fi'

const getViewerQuery = gql`
  {
    viewer {
      id
    }
  }
`

const SSOCookieAttributes = {
  domain: COOKIE_DOMAIN,
  secure: true,
  sameSite: 'lax',
  expires: 365
}

const setSSOIDCookie = async (client: any) => {
  try {
    const {
      data: { viewer }
    }: any = await client.query({
      query: getViewerQuery
    })

    // set cookies here
    cookies.set(SSO_ID_STORAGE_KEY, viewer.id, SSOCookieAttributes)
    cookies.set(SSO_ID_DATE_STORAGE_KEY, Date.now(), SSOCookieAttributes)
  } catch (err) {
    console.log(err)
  }
}

export default setSSOIDCookie
