import * as React from 'react'
import qs from 'qs'

import Logo from '../components/Logo'
import Footer from '../components/Footer'
import LoginForm from '../forms/LoginForm'
import Title from '../components/Title'
import { Text } from '../content/text'
import { setAuthTokens, clearAuthTokens } from '../helpers/authentication'
import Layout from '../layout/Layout'

class Login extends React.Component<any, any> {
  componentDidMount() {
    clearAuthTokens()
  }

  render() {
    const webView =
      qs.parse(location.search, { ignoreQueryPrefix: true }).webview === 'true'

    return (
      <Layout main withTopPadding={webView}>
        {!webView && <Logo />}
        <Layout constrained centered>
          <Title title={Text('LOGIN_VERBOSE')} text={Text('LOGIN_SUBTITLE')} />
          <LoginForm />
        </Layout>
        {!webView && <Footer />}
      </Layout>
    )
  }
}

export default Login
