import * as React from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import get from 'lodash/get'
import cookies from 'js-cookie'
import Layout from '../layout/Layout'
import Logo from '../components/Logo'
import Title from '../components/Title'
import CheckClaimOldProfileForm from '../forms/CheckClaimOldProfileForm'
import ClaimProfileWithNewUserForm from '../forms/ClaimProfileWithNewUserForm'
import UserConfirmCodeForm from '../forms/UserConfirmCodeForm'
import LoginForm from '../forms/LoginForm'
import Footer from '../components/Footer'
import Button from '../components/Button'
import { Text } from '../content/text'
import {
  setAuthTokens,
  clearAuthTokens,
  REFRESHTOKEN_STORAGE_KEY
} from '../helpers/authentication'
import { identity } from '../helpers/identity'
import { urlQueryStringGet, getQueryStringParams } from '../helpers/urlQueryString'
import setSSOIDCookie from '../helpers/setSsoidCookie'
import { FormProps } from '../types/FormProps'
import * as formCss from '../styles/Form.css'
import * as css from './ClaimOldProfile.css'

const claimLilyProfileAsCurrentUser = gql`
  mutation claimLilyProfileAsCurrentUser(
    $legacyUsername: String!
    $legacyPassword: String!
    $fullName: String
  ) {
    claimLilyProfileAsCurrentUser(
      legacyUsername: $legacyUsername
      legacyPassword: $legacyPassword
      fullName: $fullName
    )
  }
`
const enum ClaimStage {
  blank = 'BLANK',
  claimOldProfile = 'CLAIM_PROFILE',
  createUser = 'CREATE_USER',
  confirmUser = 'CONFIRMATION_USER',
  userExists = 'USER_EXISTS',
  logInForm = 'LOG_IN_FORM',
  userLoggedIn = 'USER_LOGGED_IN'
}

type State = {
  legacyUsername: string
  legacyPassword: string
  /**
   * `subId` is the subject id received in the response from
   * `claimLilyProfileAsCurrentUser`.
   */
  subId: string
  fullName: string
  username: string
  password: string
  signUpState: string
  confirmPassword: string
  usernameType: string
  claimStage: ClaimStage
  verificationSuccess: boolean
  loggedInAs: string
  claimCompleted: boolean
  needsFullName: boolean
}

const getInitialState = () => {
  const initialState = {
    legacyUsername: '',
    legacyPassword: '',
    subId: '',
    fullName: '',
    username: '',
    password: '',
    signUpState: undefined,
    confirmPassword: '',
    usernameType: 'email',
    claimStage: ClaimStage.claimOldProfile,
    verificationSuccess: false,
    loggedInAs: '',
    claimCompleted: false,
    needsFullName: false
  }
  return initialState
}

interface Props extends FormProps {
  onLoginSuccess?: Function
  client: any
}

// TODO move this in to the component
let redirectUrl
class ClaimOldProfile extends React.Component<Props, State> {
  static defaultProps = {
    onLoginSuccess: identity
  }

  state = getInitialState()

  private _isMounted: boolean = false

  componentDidMount() {
    const { history, client } = this.props

    this._isMounted = true

    this.setState({
      signUpState: JSON.stringify(getQueryStringParams())
    })

    const historyClaimStage = get(history, 'location.state.claimStage')
    const historyloggedInAs = get(history, 'location.state.loggedInAs')
    const historyLegacyUsername = get(history, 'location.state.legacyUsername')
    const historyLegacyPassword = get(history, 'location.state.legacyPassword')
    const historyFullName = get(history, 'location.state.fullName')

    if (historyClaimStage) {
      this.setState(
        {
          claimStage: ClaimStage.blank,
          loggedInAs: historyloggedInAs ? historyloggedInAs : '',
          legacyUsername: historyLegacyUsername ? historyLegacyUsername : '',
          legacyPassword: historyLegacyPassword ? historyLegacyPassword : '',
          fullName: historyFullName ? historyFullName : ''
        },
        function(this: any) {
          const { legacyUsername, legacyPassword, fullName, loggedInAs } = this.state
          if (loggedInAs === 'existingUser') {
            client
              .mutate({
                mutation: claimLilyProfileAsCurrentUser,
                variables: { legacyUsername, legacyPassword, fullName }
              })
              .then(
                ({ data }) => {
                  if (get(data, 'claimLilyProfileAsCurrentUser')) {
                    this.setState({
                      claimCompleted: true
                    })
                  }
                  this.goToClaimStageState(ClaimStage.userLoggedIn)
                },
                () => {
                  this.goToClaimStageState(ClaimStage.userLoggedIn)
                }
              )
          } else if (loggedInAs === 'newUser') {
            this.setState({
              claimCompleted: true
            })
            this.goToClaimStageState(ClaimStage.userLoggedIn)
          }

          redirectUrl = urlQueryStringGet(cookies.get(REFRESHTOKEN_STORAGE_KEY))
        }
      )
    } else {
      clearAuthTokens()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setFormState = (valueName, isCheckbox = false) => (e) => {
    this.setState({
      [valueName]: isCheckbox ? get(e, 'target.checked') : get(e, 'target.value', e)
    })
  }

  canClaimCheckCompleted = (needsFullName) => {
    this.setState({
      claimStage: ClaimStage.createUser,
      username: this.state.legacyUsername,
      password: this.state.legacyPassword,
      confirmPassword: this.state.legacyPassword,
      needsFullName: needsFullName ? true : false
    })
  }

  goToClaimStageState = (state) => {
    this.setState({
      claimStage: state
    })
  }

  onVerificationCompleted = (data) => {
    if (get(data, 'confirmUser.success', false)) {
      const loginResult = data.confirmUser.loginResult
      this.setState({
        verificationSuccess: true
      })
      this.logIn(loginResult)
    }
  }

  logIn = (loginResult) => {
    const { onLoginSuccess, client } = this.props

    this.pushToHistory('newUser')
    setAuthTokens(loginResult)
    onLoginSuccess(loginResult)
    setSSOIDCookie(client)
    redirectUrl = urlQueryStringGet(loginResult.refreshToken)
  }

  existingUserLoggedIn = () => {
    // push to history since login forces component to mount again
    this.pushToHistory('existingUser')
  }

  pushToHistory = (user) => {
    const { history } = this.props
    const { legacyUsername, legacyPassword, fullName } = this.state

    history.push({
      search: window.location.search,
      state: {
        claimStage: ClaimStage.userLoggedIn,
        loggedInAs: user,
        legacyUsername,
        legacyPassword,
        fullName
      }
    })
  }

  render() {
    const {
      legacyUsername,
      legacyPassword,
      subId,
      fullName,
      username,
      password,
      signUpState,
      confirmPassword,
      usernameType,
      claimStage,
      verificationSuccess,
      loggedInAs,
      claimCompleted,
      needsFullName
    } = this.state

    const { history } = this.props

    return (
      <Layout main>
        <Logo />
        <Layout constrained centered>
          {/*blank state is to prevent wrong state from flashing after component is remounted after login */}
          {claimStage === ClaimStage.blank && <></>}

          {claimStage === ClaimStage.claimOldProfile && (
            <>
              <Title
                title={Text('CLAIM_OLD_USER')}
                text={Text('FILL_OLD_CREDENTIALS')}
              />
              <CheckClaimOldProfileForm
                legacyUsername={legacyUsername}
                legacyPassword={legacyPassword}
                onChange={this.setFormState}
                onClaimCheckSuccess={this.canClaimCheckCompleted}
              />
            </>
          )}
          {claimStage === ClaimStage.createUser && (
            <>
              <Title
                title={Text('CREATE_USER_VERBOSE')}
                text={Text('CLAIMING_USERNAME_INFO', 'Lily')}
              />
              <ClaimProfileWithNewUserForm
                legacyUsername={legacyUsername}
                legacyPassword={legacyPassword}
                fullName={fullName}
                username={username}
                password={password}
                signUpState={signUpState}
                confirmPassword={confirmPassword}
                onChange={this.setFormState}
                needsFullName={needsFullName}
                onUserExistsError={() => {
                  this.goToClaimStageState(ClaimStage.userExists)
                }}
                onClaimCompleted={(data) => {
                  const newSubId = data.claimLilyProfile.userSub
                  this.setState({
                    subId: newSubId
                  })
                  this.goToClaimStageState(ClaimStage.confirmUser)
                }}
              />
            </>
          )}
          {claimStage === ClaimStage.confirmUser && (
            <>
              <Title
                title={Text(
                  usernameType === 'email'
                    ? 'CONFIRM_EMAIL'
                    : usernameType === 'phone_number'
                      ? 'CONFIRM_PHONE'
                      : 'CONFIRM_ACCOUNT'
                )}
                text={Text(
                  usernameType === 'email'
                    ? 'CONFIRM_EMAIL_INGRESS'
                    : usernameType === 'phone_number'
                      ? 'CONFIRM_PHONE_INGRESS'
                      : 'CONFIRM_ACCOUNT_INGRESS'
                )}
              />
              {!verificationSuccess &&
                usernameType !== 'email' && (
                  <p className={formCss.noticetext}>
                    <Text text={Text('CONFIRM_NOTICE')} />
                  </p>
                )}
              <UserConfirmCodeForm
                subId={subId}
                username={username}
                onCompleted={this.onVerificationCompleted}
                verified={verificationSuccess}
              />
            </>
          )}
          {claimStage === ClaimStage.userExists && (
            <>
              <Title
                title={Text('CONNECT_PROFILE_TO_USER', 'Lily')}
                text={Text('CONNECT_PROFILE_TO_USER_TEXT', 'Lily')}
              />
              <Button
                label={Text('LOGIN_ACCOUNT_VERBOSE')}
                onClick={() => {
                  this.goToClaimStageState(ClaimStage.logInForm)
                }}
                inverted
              />

              <div className={css.createNewUserBlock}>
                <p>
                  <b>
                    <Text text={'DONT_WANT_TO_USE_SAME'} />
                  </b>
                </p>
                <Button
                  label={Text('CREATE_NEW_USER')}
                  onClick={() => {
                    this.setState({
                      username: '',
                      password: '',
                      confirmPassword: ''
                    })
                    this.goToClaimStageState(ClaimStage.createUser)
                  }}
                />
              </div>
            </>
          )}
          {claimStage === ClaimStage.logInForm && (
            <>
              <Title
                title={Text('LOGIN_VERBOSE')}
                text={Text('LOGIN_WITH_NEW_ACCOUNT')}
              />
              <LoginForm
                newUserLink={false}
                withRedirect={false}
                loginCompleted={this.existingUserLoggedIn}
              />
            </>
          )}
          {claimStage === ClaimStage.userLoggedIn && (
            <>
              <Title
                title={
                  claimCompleted
                    ? loggedInAs === 'existingUser'
                      ? Text('CONFIRMATION')
                      : Text('USER_CREATED') + '!'
                    : Text('ERROR')
                }
                text={
                  claimCompleted
                    ? loggedInAs === 'existingUser'
                      ? Text('OLD_PROFILE_CONNECTED_TO_ACCOUNT', 'Lily')
                      : Text('USER_CREATED_DESCRIPTION')
                    : Text('ERROR_CLAIMING_PROFILE')
                }
              />
              {claimCompleted ? (
                <Button label={Text('CONTINUE_TO')} href={redirectUrl} inverted />
              ) : (
                <Button
                  label={Text('TRY_AGAIN')}
                  onClick={() => {
                    this.setState(getInitialState())
                    this.goToClaimStageState(ClaimStage.claimOldProfile)
                    history.replace({
                      search: window.location.search,
                      state: {}
                    })
                  }}
                  inverted
                />
              )}
            </>
          )}
        </Layout>
        <Footer />
      </Layout>
    )
  }
}

export default withApollo<any, any>(ClaimOldProfile)
