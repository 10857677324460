import gql from 'graphql-tag'
import get from 'lodash/get'
import * as React from 'react'
import { withApollo } from 'react-apollo'

import Loading from '../components/Loading'
import { MutateProps } from '../types/MutateProps'
import { mutate } from '../apollo/Mutation'
import { routes } from '../routes'
import { Text } from '../content/text'
import { setAuthTokens } from '../helpers/authentication'
import { translateError } from '../helpers/translateError'
import { getReturnUrlFromParams } from '../helpers/urlQueryString'
import setSSOIDCookie from '../helpers/setSsoidCookie'

interface Props extends MutateProps {
  client: any
  subId: string
  code: string
  history: any
}

type State = {
  confirmSuccess: Boolean
}

const confirmUserMutation = gql`
  mutation confirmUser($subId: String!, $code: String!) {
    confirmUser(subId: $subId, code: $code) {
      success
      errorCode
      errorMessage
      signUpState
      loginResult {
        accessToken
        refreshToken
        idToken
        tokenType
        expiresIn
      }
    }
  }
`

@mutate(confirmUserMutation)
class ConfirmUserWithLink extends React.Component<Props, State> {
  state = {
    confirmSuccess: false
  }

  componentDidMount() {
    const { history, mutator, client, subId, code } = this.props
    mutator({
      variables: {
        subId,
        code
      }
    }).then((result) => {
      if (get(result, 'data.confirmUser.success', false)) {
        const loginResult = result.data.confirmUser.loginResult
        const signUpStateString = result.data.confirmUser.signUpState
        if (signUpStateString) {
          const {
            from: fromParam,
            return: returnParam,
            state: stateParam
          } = JSON.parse(signUpStateString)
          const returnUrl = getReturnUrlFromParams(loginResult.refreshToken, {
            from: fromParam,
            return: returnParam,
            state: stateParam
          })
          if (returnUrl) {
            window.location.href = returnUrl
          } else {
            setAuthTokens(loginResult)
            setSSOIDCookie(client)
            history.push(routes.manageCredentials())
          }
        } else {
          setAuthTokens(loginResult)
          setSSOIDCookie(client)
          history.push(routes.manageCredentials())
        }
      } else {
        history.push(routes.manageCredentials())
      }
    })
  }

  render() {
    const { mutationError, mutationLoading } = this.props
    const translatedError = translateError(mutationError)
    return (
      <Loading
        loading={mutationLoading}
        label={Text('CONFIRM_ACCOUNT_LOADING')}
        doneLabel={Text('CONFIRM_ACCOUNT_SUCCESS')}
        error={translatedError}
      />
    )
  }
}

export default withApollo<any, any>(ConfirmUserWithLink)
