import forms from './forms'
import ui from './ui'
import user from './user'
import validation from './validation'
import errors from './errors'
import account from './account'

const content = {
  forms,
  account,
  ui,
  user,
  errors,
  validation
}

export default content
