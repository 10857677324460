import * as React from 'react'

const validationContext = React.createContext({
  onValidation: (name) => (isValid) =>
    console.log(`Validation used without a validation context in field ${name}.`),
  showErrors: false,
  hasErrors: false
})

export const { Provider, Consumer } = validationContext
