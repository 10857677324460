const errors = {
  InvalidUsernameOrPasswordException:
    'Antamasi tunnus tai salasana on virheellinen.',
  NotAuthorizedException: 'Nykyinen salasana väärin',
  LimitExceededException: 'Odota hetki yrittääksesi uudestaan',
  InvalidPasswordException:
    'Salasana ei ole tarpeeksi vahva. Salasanan pitää olla vähintään kahdeksan merkkiä pitkä.',
  CodeMismatchException: 'Antamasi vahvistuskoodi on virheellinen.',
  UsernameExistsException: 'Käyttäjätunnus on jo olemassa',
  EntityNotFoundException: 'Tunnusta ei löytynyt',
  AccessDeniedException: 'Syöttämäsi käyttäjätunnus tai salasana ei kelpaa',
  UsernameAlreadyClaimedException: 'Tunnus on jo otettu käyttöön',
  SignUpsDisabledException: 'Rekisteröityminen on tilapäisesti pois käytöstä.',
  BannedException: 'Syöttämäsi käyttäjätunnuksen käyttö on estetty.',
  InvalidUsernameException: 'Käyttäjätunnus ei kelpaa.',
  UserNotFoundException: 'Käyttäjätunnusta ei löytynyt.',
  PhoneNumberExistsException: 'Syöttämäsi puhelinnumero on jo olemassa.',
  EmailExistsException: 'Syöttämäsi sähköpostiosoite on jo olemassa.',
  ExpiredCodeException: 'Tämä koodi on jo käytetty. Yritä uudestaan.',
  LilyProfileFullNameRequiredException: 'Nimi vaaditaan.',
  UserHasAgreementsException:
    'Tililläsi on voimassa oleva tilaus. Peruuta tilauksesi ennen tilin poistamista.'
}

export default errors
