import * as React from 'react'
import FormButtons from '../forms/FormButtons'
import * as css from '../styles/Form.css'
import Loading from './Loading'

export default ({
  error = false,
  show = true,
  loading = false,
  ...rest
}: {
  error: boolean | string
  show?: boolean
  loading?: boolean
  onCancel?: () => undefined
}) =>
  show ? (
    <div className={css.row}>
      <Loading error={error} loading={loading} />
      {!loading && <FormButtons {...rest} />}
    </div>
  ) : null
