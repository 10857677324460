import classnames from 'classnames'
import * as React from 'react'
import { MdEdit } from 'react-icons/lib/md'
import { NavLink } from 'react-router-dom'
import ValidationMessage from '../components/ValidationMessage'
import validateInput from '../helpers/validateInput'
import { AnyFunction } from '../types/Function'
import * as css from './Field.css'
import Input, { InputProps } from './Input'

export interface FieldProps extends InputProps {
  label?: React.ReactNode
  labelRight?: React.ReactNode
  labelRightRoute?: string
  className?: string
  onEditClick?: AnyFunction
  children?: any
  style?: any
  showErrors?: boolean
  errorRight?: boolean
  errors?: string[]
  errorMessage?: string
  message?: React.ReactNode
  onValidation?: AnyFunction
  validationRules?: string
  validationReference?: {}
}

const Field = (props) => {
  const {
    label,
    labelRight,
    labelRightRoute,
    className = '',
    children,
    id,
    disabled,
    onEditClick = false,
    style,
    errorRight = false,
    showErrors,
    errors = [],
    errorMessage,
    message,
    onValidation, // Need to exclude this from rest
    validationRules, // Need to exclude this from rest
    validationReference, // Need to exclude this from rest
    ...rest
  } = props

  const InputComponent = children ? (
    React.Children.map(children, (child: any) =>
      React.cloneElement(child, {
        id,
        disabled,
        ...rest
      })
    )
  ) : (
    <Input id={id} className={css.input} disabled={disabled} {...rest} />
  )

  const errorMessages = !disabled && showErrors && errors.length ? errors : []

  // Create a label if the Field was provided one. Use a label element
  // only if the Field was provided with an id prop. This can be used
  // to avoid nested labels which can lead to buggy UI.
  const LeftLabel = label ? (
    id ? (
      <label htmlFor={id} className={css.fieldLabel}>
        {label}
      </label>
    ) : (
      <div className={css.fieldLabel}>{label}</div>
    )
  ) : null

  // Figure out what the right label should be.
  const rightLabelText =
    errorMessages.length && errorRight ? errorMessages[0] : labelRight
  const rightLabelType =
    (errorMessages.length && errorRight) || !labelRightRoute ? 'span' : NavLink

  // Construct a React element with the above info.
  const RightLabel = rightLabelText
    ? React.createElement(
        rightLabelType,
        {
          className: classnames(
            css.rightLabel,
            errorMessages.length ? css.errorLabel : ''
          ),
          to: labelRightRoute ? labelRightRoute : undefined
        },
        rightLabelText
      )
    : null

  const classes = classnames({
    [css.fieldWrapper]: true,
    [css.invalid]: errorMessages.length,
    [className]: true
  })

  return (
    <div className={classes} style={style}>
      <div className={css.fieldBody}>
        {LeftLabel || RightLabel ? (
          <div className={css.labels}>
            {LeftLabel}
            {RightLabel}
          </div>
        ) : null}
        {InputComponent}
        {onEditClick &&
          disabled && (
            <button type="button" onClick={onEditClick} className={css.editButton}>
              <MdEdit className={css.editIcon} size={20} />
            </button>
          )}
      </div>
      {!errorRight && (
        <ValidationMessage errors={errorMessages}>{errorMessage}</ValidationMessage>
      )}
      {message}
    </div>
  )
}

export default validateInput(Field)
