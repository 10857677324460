/**
 * This file contains the custom <Query> component and the @query decorator.
 * The <Query> component unifies loading and error behavior across the application.
 *
 * Use <Query> similarly to react-routers <Route>. Pass a GraphQL query, a Component to
 * render with the data and optionally some variables. The Component will be rendered
 * with the query data in the `queryResult` prop, plus all other props that was passed
 * to the <Query> component.
 *
 * The decorator works much the same way, except as a higher-order component. It takes
 * a query and an optional function that should return variables for the query to use.
 * You can also add a `variables` prop to the decorated component when mounting it.
 */

import * as React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import get from 'lodash/get'
import Loading from '../components/Loading'
import { Text } from '../content/text'
import { translateError } from '../helpers/translateError'
import Layout from '../layout/Layout'
import { handleAuthError } from './handleAuthError'
import { RouteComponentProps, withRouter } from 'react-router'
import { routes } from '../routes'
import ForceLogout from '../helpers/ForceLogout'

interface Props extends RouteComponentProps<any> {
  query?: any
  component: any
  variables?: object
  showWhileLoading?: boolean
  onError?: any
}

export const Query = withRouter(
  ({
    showWhileLoading = false,
    query: queryProp,
    variables,
    location,
    component: Component,
    onError,
    ...rest
  }: Props) => {
    const queryName = get(queryProp, 'definitions[0].name.value')

    return (
      <ApolloQuery
        fetchPolicy="cache-and-network"
        variables={variables}
        query={queryProp}>
        {({ loading, error, data, refetch }) => {
          handleAuthError(error)

          const queryResult = get(data, queryName, data)

          // result is of Result type { success, errorCode, errorMessage }
          const queryError =
            queryResult && queryResult.errorCode ? queryResult.errorCode : error

          const translatedError = translateError(queryError)

          onError && queryError && onError(queryError)

          if ((loading || queryError) && !showWhileLoading) {
            return (
              <Loading
                loading={loading}
                error={translatedError}
                label={Text('FETCHING_DATA')}
              />
            )
          }

          return <Component queryResult={data} refetchQuery={refetch} {...rest} />
        }}
      </ApolloQuery>
    )
  }
)

export const query = (
  staticQuery,
  getVariables: Function = () => null
): Function => (Component) => ({
  query: queryProp = staticQuery,
  ...rest
}: {
  query: any
  variables: any
}) => {
  const variablesFromProps = getVariables(rest) // Null if no function provided
  const { variables = variablesFromProps } = rest

  return (
    <Query query={queryProp} component={Component} {...rest} variables={variables} />
  )
}
