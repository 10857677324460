import ApolloClient from 'apollo-client/ApolloClient'
import * as React from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router } from 'react-router-dom'
import RouteConfig from './Router'
import { hash } from './helpers/hash'
import * as get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import { AuthenticationState } from './types/AuthenticationState'

export default inject('state')(
  observer(
    ({
      client,
      state
    }: {
      state: { userAuth: AuthenticationState }
      client: ApolloClient<any>
    }) => {
      const { userAuth } = state

      return (
        <ApolloProvider client={client}>
          <Router>
            <RouteConfig key={`user_${hash(get(userAuth, 'accessToken', ''))}`} />
          </Router>
        </ApolloProvider>
      )
    }
  )
)
