import * as without from 'lodash/without'
import * as React from 'react'
import * as ValidationContext from '../helpers/validationContext'
import { AnyFunction } from '../types/Function'

type State = {
  showErrors: boolean
  invalidFields: any[]
}

type Props = {
  className?: string
  onSubmit?: AnyFunction
  children: React.ReactNode
  style?: {}
}

class Form extends React.Component<Props, State> {
  state = {
    showErrors: false,
    invalidFields: []
  }

  onValidation = (name) => (isValid) => {
    this.setState(({ invalidFields, showErrors }) => {
      const nextState = { invalidFields, showErrors }

      if (!isValid && invalidFields.indexOf(name) === -1) {
        nextState.invalidFields = [...invalidFields, name]
      } else if (isValid && invalidFields.indexOf(name) > -1) {
        nextState.invalidFields = without(invalidFields, name)
      }

      if (!nextState.invalidFields.length) {
        nextState.showErrors = false
      }

      return nextState
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { onSubmit } = this.props

    if (this.state.invalidFields.length > 0) {
      this.setState({
        showErrors: true
      })
    } else {
      this.setState({
        showErrors: false
      })

      onSubmit(e)
    }
  }

  render() {
    const { children, className, style } = this.props
    const { showErrors, invalidFields } = this.state

    const validationContext = {
      onValidation: this.onValidation,
      showErrors,
      hasErrors: invalidFields.length > 0
    }

    return (
      <ValidationContext.Provider value={validationContext}>
        <form
          noValidate
          className={className}
          style={style}
          onSubmit={this.onSubmit}>
          {children}
        </form>
      </ValidationContext.Provider>
    )
  }
}

export default Form
