import qs from 'qs'
import { routes } from '../routes'

const { REACT_APP_PLATTA_URLS } = process.env

export const urlQueryStringGet = (refreshToken) => {
  return getReturnUrlFromParams(refreshToken, getQueryStringParams())
}

export const urlQueryStringRedirect = (refreshToken, history) => {
  const redirectUrl = urlQueryStringGet(refreshToken)
  if (redirectUrl) {
    window.location.href = redirectUrl
  } else {
    history.push(routes.manageCredentials())
  }
}

export function getReturnUrlFromParams(refreshToken, params) {
  const paramFrom = params.from
  const paramReturn = params.return
  const paramState = params.state
  if (!paramReturn) {
    return null
  }

  const plattaUrls = REACT_APP_PLATTA_URLS ? REACT_APP_PLATTA_URLS.split(',') : []
  // fromPlattaUrl finds the url for the query param 'from' from the array of plattaUrls
  const fromPlattaUrl =
    paramFrom && plattaUrls.find((plattaUrl) => plattaUrl.includes(paramFrom))
  if (!fromPlattaUrl) {
    return null
  }

  const returnUrl = `${fromPlattaUrl}${paramReturn}?${qs.stringify({
    state: paramState,
    refreshToken
  })}`

  return returnUrl
}

export function getQueryStringParams(): {
  from?: string
  return?: string
  state?: string
} {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  return params
}
