const ui = {
  LOGGING_IN: 'Kirjaudutaan...',
  LOGGED_IN: 'Kirjautunut',
  SENDING_REQUEST: 'Lähetetään pyyntö...',
  SENDING: 'Lähetetään...',
  SENT: 'Lähetetty',
  FETCHING_DATA: 'Haetaan tietoja...',
  DATA_FETCHED: 'Tiedot haettu',
  SAVING: 'Tallennetaan',
  SAVED: 'Tiedot tallennettu',
  CHANGING_PASSWORD: 'Muutetaan salasanaa...',
  LOGIN: 'Kirjaudu',
  LOGIN_VERBOSE: 'Kirjaudu sisään',
  LOGIN_ACCOUNT_VERBOSE: 'Kirjaudu A-tunnuksella',
  LOGIN_SUBTITLE: 'A-lehtien palveluihin kirjaudutaan A-tunnuksella.',
  CLICK_HERE: 'klikkaa tästä',
  NAV_BUTTON_LABEL: 'Valikko',
  REMOVE: 'Poista',
  PASSWORD_CHANGE_SUCCESSFUL:
    'Salasanasi on vaihdettu onnistuneesti kaikkiin alla näkyviin palveluihin.',
  DATA_CHANGE_SUCCESSFUL: 'Tiedot tallennettu onnistuneesti.',
  CREATING: 'Luodaan...',
  CREATED: 'Luotu',
  TRY_AGAIN: 'Yritä uudelleen',
  CONTINUE_TO: 'Jatka palveluun'
}

export default ui
