import * as React from 'react'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { mutate } from '../apollo/Mutation'
import Form from './Form'
import Field from './Field'
import CodeInput from './CodeInput'
import Loading from '../components/Loading'
import FormButtons from './FormButtons'
import { translateError } from '../helpers/translateError'
import ResendConfirmationCode from '../components/ResendConfirmationCode'
import { Text } from '../content/text'
import { FormProps } from '../types/FormProps'
import * as css from '../styles/Form.css'

interface Props extends FormProps {
  username: string
  /**
   * `subId` is the subject id from API that is used to confirm the user.
   */
  subId: string
  verified: boolean
}

type State = {
  code: string
}

const confirmUserMutation = gql`
  mutation confirmUser($subId: String!, $code: String!) {
    confirmUser(subId: $subId, code: $code) {
      success
      errorCode
      errorMessage
      loginResult {
        accessToken
        refreshToken
        idToken
        tokenType
        expiresIn
      }
    }
  }
`
@mutate(confirmUserMutation)
class UserConfirmCodeForm extends React.Component<Props, State> {
  state = {
    code: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  sendRequest = (e) => {
    const { mutator, subId } = this.props
    const { code } = this.state

    e.preventDefault()

    mutator({
      variables: {
        subId,
        code
      }
    })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      onCancel,
      username,
      verified
    } = this.props

    const { code } = this.state

    const translatedError = translateError(mutationError)

    return (
      <Form className={css.form} onSubmit={this.sendRequest}>
        {!verified && (
          <Field
            validationRules="required|digits:6"
            name="Koodi"
            onChange={this.setFormState('code')}
            value={code}
            id="code">
            <CodeInput />
          </Field>
        )}
        <Loading
          error={translatedError}
          label={Text('CONFIRM_PROGRESS')}
          loading={mutationLoading}
        />
        {!verified && (
          <>
            <FormButtons
              onCancel={onCancel}
              className={css.buttonRow}
              submitLabel={Text('CONFIRM')}
            />
            <ResendConfirmationCode username={username} />
          </>
        )}
      </Form>
    )
  }
}

export default UserConfirmCodeForm
