import * as React from 'react'
import qs from 'qs'
import { FaSpinner } from 'react-icons/lib/fa'

import { AuthenticationState } from '../types/AuthenticationState'
import { routes } from '../routes'
import * as css from './ssoCallback.css'
import { refreshAccessToken } from '../apollo/client'

interface SSOCallbackProps {
  history: any
}

interface SSOCallbackState {
  error: string | undefined
}

class SSOCallback extends React.Component<SSOCallbackProps, SSOCallbackState> {
  refreshToken: string = qs.parse(location.search, { ignoreQueryPrefix: true })
    .refreshToken

  constructor(props) {
    super(props)
    this.state = {
      error: undefined
    }
  }

  onError = (error) => {
    const { history } = this.props
    this.setState({ error })
    console.error(error)
    history.replace('/')
  }

  componentDidMount() {
    const { REACT_APP_TOKEN_STORAGE_KEY } = process.env
    const { history } = this.props

    // Redirect to front page if refresh token is absent
    if (!this.refreshToken) {
      if (history) {
        history.push(routes.home())
      } else {
        window.location.href = '/'
      }
      return
    }

    // By inserting an expired token with a valid refresh token to local storage,
    // the Apollo link will take care of refreshing the token automatically during the next navigation
    refreshAccessToken(this.refreshToken).then(() => {
      history.push(routes.manageCredentials())
    })
  }

  render() {
    return (
      <div className={css.container}>
        <div className={css.spinner}>
          <FaSpinner />
        </div>
      </div>
    )
  }
}

export default SSOCallback
