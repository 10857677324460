import * as React from 'react'
import { routes } from '../routes'
import { Text } from '../content/text'
import Link from './Link'

export default () => (
  <p className="smallPrint">
    <Text text="HAVE_USER_ACCOUNT_QUESTION" />{' '}
    <Link path={routes.login()}>
      <Text text="LOGIN_VERBOSE" />
    </Link>
  </p>
)
