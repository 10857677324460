import * as React from 'react'

import { routes } from '../routes'
import { Text } from '../content/text'
import Button from './Button'
import * as css from './newUser.css'

export default () => {
  const createUserUrl = `${routes.createUser()}${window.location.search || ''}`

  return (
    <div className={css.newUser}>
      <Text text="NEW_USER_QUESTION" />{' '}
      <Button type="Link" to={createUserUrl}>
        <Text text="CREATE_USER" />
        {'!'}
      </Button>
    </div>
  )
}
