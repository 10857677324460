const user = {
  AUTH_EMAIL: 'Sähköpostiosoite, jota käytät kirjautumiseen.',
  AUTH_PHONE: 'Puhelinnumero, jota käytät kirjautumiseen.',
  AUTH_NEW_PASSWORD:
    'Salasanan pitää olla vähintään kahdeksan merkkiä pitkä. Salasana voi sisältää isoja ja pieniä kirjaimia, numeroita ja yleisimpiä erikoismerkkejä',
  AUTH_TITLE: 'Kirjaudu sisään',
  AUTH_INGRESS: 'A-lehtien palveluihin kirjaudutaan A-tunnuksella.',
  AUTH_SERVICES: 'A-tunnuksella voit kirjautua seuraaviin palveluihin.',
  FORGOT_PASSWORD: 'Unohditko salasanasi?',
  FORGOT_PASSWORD_INGRESS:
    'Täytä alle tunnuksenasi oleva sähköposti tai puhelinnumero. Lähetämme sinulle koodin, jota tarvitset vaihtaessasi salasanaa.',
  USERNAME: 'Käyttäjätunnus',
  CREDENTIALS_FORM_TITLE: 'Tilin asetukset',
  CREDENTIALS_FORM_INGRESS:
    'Kun kirjaudut A-lehtien verkkopalveluihin, käyttäjätunnuksesi on alla oleva sähköposti tai puhelinnumero.',
  CURRENT_PASSWORD: 'Nykyinen salasana',
  NEW_PASSWORD: 'Uusi salasana',
  PASSWORD: 'Salasana',
  DESIRED_PASSWORD: 'Haluamasi salasana',
  VERIFY_PASSWORD: 'Vahvista salasana',
  VERIFY_NEW_PASSWORD: 'Vahvista uusi salasana',
  CONFIRM: 'Vahvista',
  CONFIRM_MESSAGE_NOT_ARRIVE: 'Jos et saanut vahvistusviestiä',
  CONFIRM_MESSAGE_NOT_ARRIVE_PRESS_CANCEL:
    'Jos et saanut vahvistusviestiä, klikkaa "Peruuta" ja yritä uudelleen.',
  CONFIRM_PROGRESS: 'Vahvistetaan...',
  CONFIRM_EMAIL: 'Vahvista sähköpostiosoitteesi',
  CONFIRM_PHONE: 'Vahvista puhelinnumerosi',
  CONFIRM_PASSWORD: 'Aseta uusi salasana',
  CONFIRM_ACCOUNT: 'Vahvista tunnuksesi',
  CONFIRM_EMAIL_INGRESS:
    'Lähetimme sinulle sähköpostia. Klikkaa sähköpostiviestissä olevaa linkkiä tai kirjoita viestissä saamasi vahvistuskoodi alla olevaan kenttään viidentoista minuutin kuluessa.',
  CONFIRM_PHONE_INGRESS:
    'Antamaasi puhelinnumeroon on lähetetty vahvistuskoodi. Kirjoita saamasi koodi alla olevaan kenttään viidentoista minuutin kuluessa.',
  CONFIRM_ACCOUNT_INGRESS:
    'Sinulle on lähetetty vahvistuskoodi. Kirjoita saamasi koodi alla olevaan kenttään viidentoista minuutin kuluessa.',
  CONFIRM_NOTICE: 'HUOM! Ethän sulje tätä välilehteä.',
  EMAIL_OR_PHONE: 'Sähköposti tai puhelinnumero',
  CREDENTIAL: 'Tunnus',
  USED_SERVICES: 'Käytetyt palvelut',
  USED_SERVICES_INGRESS:
    'A-tunnuksesi on käytössä alla olevissa palveluissamme. Voit poistaa profiilisi yksittäisistä palveluista tai halutessasi kaikista A-lehtien palveluista poistamalla koko käyttäjätunnuksesi.',
  DELETE_LILY_PROFILE:
    'Jos poistat profiilisi Lily.fi:stä, poistetaan se pysyvästi eikä sitä voi peruuttaa. Kirjoittamasi kommentit jäävät näkymään palveluun, mutta niistä poistetaan nimimerkkisi.',
  DELETE_LILY_PROFILE_ADDITIONAL_TEXT:
    'HUOM! Jos haluat poistaa myös mahdollisen blogisi, tee se ensin blogin hallinnasta.',
  DELETE_EEVA_PROFILE:
    'Jos poistat profiilisi Eeva.fi:stä, poistetaan se pysyvästi eikä sitä voi peruuttaa.',
  CUSTOMER_SERVICE_ADDITIONAL_TEXT:
    'Tunnuksen avulla voit asioida A-lehtien asiakaspalvelussa.',
  APU_ADDITIONAL_TEXT: 'Tunnuksen avulla voit kirjautua apu.fi-palveluun.',
  APU360_ADDITIONAL_TEXT: 'Tunnuksen avulla voit kirjautua Apu360-palveluun.',
  DELETING_PROFILE: 'Profiilia poistetaan',
  PROFILE_DELETED: 'Profiilisi on poistettu onnistuneesti',
  CREATE_USER: 'Luo tunnus',
  CREATE_USER_VERBOSE: 'Luo A-tunnus',
  CREATE_NEW_USER: 'Luo uusi tunnus',
  USER_CREATED: 'Tunnus luotu',
  USER_CREATED_DESCRIPTION:
    'A-tunnus on nyt luotu. Voit hallita sisällön, mainonnan ja viestinnän kohdentamisvalintoja A-lehtien sähköisessä asiakaspalvelussa omien tietojen kautta. Evästeisiin perustuvaa mainonnan kohdentamista voit hallita A-lehtien <a href="https://tietosuoja.a-lehdet.fi/asetukset" target="_blank">evästeasetuksissa</a>.',
  DONT_WANT_TO_USE_SAME: 'Etkö halua käyttää samaa tunnusta?',
  LOGIN_WITH_NEW_ACCOUNT:
    'Kirjautuminen on uudistunut. Jos olet jo luonut uuden tunnuksen, kirjaudu sisään tästä.',
  CREATE_USERNAME_INFO:
    'Käyttäjätunnukseksi voit valita joko sähköpostiosoitteesi tai puhelinnumerosi. Jatkossa voit käyttää samaa tunnusta kaikissa A-lehtien palveluissa.',
  CLAIMING_USERNAME_INFO:
    'Ottaaksesi vanhan %s-tunnuksesi käyttöön tulee sinun luoda A-tunnus. Käyttäjätunnukseksi voit valita joko sähköpostiosoitteesi tai puhelinnumerosi. Jatkossa voit käyttää samaa tunnusta kaikissa A-lehtien palveluissa.',
  CONNECT_PROFILE_TO_USER: 'Liitä %s-tunnuksesi A-tunnukseesi',
  CONNECT_PROFILE_TO_USER_TEXT:
    'Ottaaksesi vanhan %s-tunnuksesi käyttöön tulee sinulla olla A-tunnus. Olet jo luonut antamallasi sähköpostiosoitteella tai puhelinnumerolla A-tunnuksen johonkin A-lehtien palveluun. Jos haluat, voit kirjautua sillä myös tänne. Tunnus ei näy muille käyttäjille.',
  CREATE_USER_ADDITIONAL_TEXT:
    'Lähetämme vahvistusviestin antamaasi sähköpostiin tai puhelinnumeroon (tekstiviesti on ilmainen). Sähköpostisi tai puhelinnumerosi toimii A-tunnuksena, jota voit halutessasi käyttää kaikissa A-lehtien palveluissa.',
  HAVE_USER_ACCOUNT_QUESTION: 'Onko sinulla jo tunnus?',
  NEW_USER_QUESTION: 'Oletko uusi käyttäjä?',
  WELCOME: 'Tervetuloa!',
  CLAIM_OLD_USER: 'Ota käyttöön vanha tunnuksesi',
  FILL_OLD_CREDENTIALS: 'Täytä alle käytössäsi olleet sähköposti ja salasana.',
  CHECK_USER_INFORMATION: 'Tarkista tietosi',
  CONTINUE: 'Jatka',
  TARGETING_DISCLAIMER:
    'Näkemiäsi sisältösuosituksia ja mainoksia sekä vastaanottamaasi markkinointi- ja asiakasviestintää voidaan personoida verkossa kuluttamiesi sisältöjen ja A-tunnukseen liittyvien tilaustietojesi perusteella. Voit vaikuttaa tietojen yhdistämiseen evästeasetuksissa.',
  SEND_VERIFICATION_INFO:
    'Lähetämme vahvistusviestin antamaasi sähköpostiin tai puhelinnumeroon (tekstiviesti on ilmainen).',
  CONFIRMATION: 'Vahvistus',
  OLD_PROFILE_CONNECTED_TO_ACCOUNT:
    'Vanha %s-tunnuksesi on nyt liitetty A-tunnukseesi.',
  ERROR: 'Virhe',
  ERROR_CLAIMING_PROFILE:
    'Tunnuksen liittämisessä on tapahtunut virhe. Yritä hetken päästä uudestaan.',
  CONFIRM_ACCOUNT_LOADING: 'Vahvistetaan tunnusta',
  CONFIRM_ACCOUNT_SUCCESS: 'Tunnus on vahvistettu',
  GIVE_NAME_SHOWN_TO_YOU:
    'Anna nimesi. Nimi näkyy vain sinulle itsellesi omassa Lily-profiilissasi.',
  TEST_STRING: 'Hello %s'
}

export default user
