import gql from 'graphql-tag'
import * as get from 'lodash/get'
import { observer } from 'mobx-react'
import * as React from 'react'
import { withApollo } from 'react-apollo'
import { mutate } from '../apollo/Mutation'
import Loading from '../components/Loading'
import ResendCodeByCancel from '../components/ResendCodeByCancel'
import { Text } from '../content/text'
import CodeInput from '../forms/CodeInput'
import Field from '../forms/Field'
import Form from '../forms/Form'
import FormButtons from '../forms/FormButtons'
import { translateError } from '../helpers/translateError'
import * as css from '../styles/Form.css'
import { AuthenticationState } from '../types/AuthenticationState'
import { FormProps } from '../types/FormProps'
import { AnyFunction } from '../types/Function'

interface Props extends FormProps {
  attributeName: string
  attributeValue: string
  onCancel?: AnyFunction
  onVerificationCompleted?: AnyFunction
  state?: { userAuth: AuthenticationState }
  client: any
  verified: boolean
}

const verifyUserAttributeMutation = gql`
  mutation verifyUserAttribute($attributeName: String!, $code: String!) {
    verifyUserAttribute(attributeName: $attributeName, code: $code) {
      success
      errorCode
      errorMessage
    }
  }
`
const recoverUserAttributesMutation = gql`
  mutation recoverUserAttributes {
    recoverUserAttributes
  }
`

type State = {
  code
}

@mutate(verifyUserAttributeMutation)
@observer
class UpdateAttributesConfirmCodeForm extends React.Component<Props, State> {
  state = {
    code: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  sendRequest = (e) => {
    e.preventDefault()
    const { mutator, attributeName } = this.props
    const { code } = this.state

    mutator({
      variables: {
        attributeName,
        code
      }
    })
  }

  cancelRequest = async (e) => {
    const { onCancel, client } = this.props
    e.preventDefault()

    try {
      await client.mutate({ mutation: recoverUserAttributesMutation })
    } catch (e) {
      console.error(e)
    }

    onCancel()
  }

  render() {
    const { mutationLoading, mutationError, attributeValue, verified } = this.props

    const { code } = this.state

    const translatedError = translateError(mutationError)

    return (
      <Form className={css.form} onSubmit={this.sendRequest}>
        {!verified && (
          <Field
            validationRules="required|digits:6"
            name="Koodi"
            onChange={this.setFormState('code')}
            value={code}
            id="code">
            <CodeInput />
          </Field>
        )}
        <Loading
          error={translatedError}
          label={Text('CONFIRM_PROGRESS')}
          loading={mutationLoading}
        />
        {!verified && (
          <>
            <FormButtons
              onCancel={this.cancelRequest}
              className={css.buttonRow}
              submitLabel={Text('CONFIRM')}
            />
            <ResendCodeByCancel />
          </>
        )}
      </Form>
    )
  }
}

export default withApollo<any, any>(UpdateAttributesConfirmCodeForm)
