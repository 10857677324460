import classnames from 'classnames'
import * as React from 'react'
import { AnyFunction } from '../types/Function'
import * as css from './Input.css'

export interface InputProps {
  type?: string
  onChange?: AnyFunction
  value?: string
  checked?: boolean
  autoComplete?: string
  name: string
  id?: string
  className?: string
  style?: any
  placeholder?: string
  disabled?: boolean
  min?: any
  max?: any
}

export default ({ className, ...rest }: InputProps) => (
  <input className={classnames(css.input, className)} {...rest} />
)
