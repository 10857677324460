import * as React from 'react'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { mutate } from '../apollo/Mutation'
import { Text } from '../content/text'
import Form from '../forms/Form'
import Field from '../forms/Field'
import Checkbox from '../forms/CheckboxInput'
import HaveAccount from '../components/HaveAccount'
import TargetingDisclaimer from '../components/TargetingDisclaimer'
import { routes } from '../routes'
import Link from '../components/Link'
import Loading from '../components/Loading'
import Tooltip from '../components/Tooltip'
import FormButtons from '../forms/FormButtons'
import { isEmail } from '../helpers/isEmail'
import { isPhone } from '../helpers/isPhone'
import { errorHasMessage } from '../helpers/graphql'
import * as css from '../styles/Form.css'

const createUserMutation = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $signUpState: String = null
  ) {
    createUser(username: $username, password: $password, signUpState: $signUpState) {
      userSub
    }
  }
`

@mutate(createUserMutation)
class CreateUserForm extends React.Component<any, any> {
  setFormState = (valueName, isCheckbox = false) => (e) => {
    this.setState({
      [valueName]: isCheckbox ? get(e, 'target.checked') : get(e, 'target.value', e)
    })
  }

  usernameType = () => {
    const { username } = this.props
    return isEmail(username) ? 'email' : isPhone(username) ? 'phoneNumber' : ''
  }

  sendRequest = (e) => {
    e.preventDefault()
    const {
      mutator,
      username,
      password,
      signUpState,
      onChange,
      onCreationCompleted
    } = this.props

    const usernameType = this.usernameType()
    onChange('usernameType')(usernameType)

    mutator({
      variables: {
        username,
        password,
        signUpState
      }
    }).then((result) => {
      onCreationCompleted(result)
    })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      onChange,
      username,
      password,
      confirmPassword
    } = this.props

    const hasLilyLegacyUsernameConflictException =
      !!mutationError &&
      errorHasMessage(mutationError, 'LilyLegacyUsernameConflictException')

    return (
      <Form className={css.form} onSubmit={this.sendRequest}>
        <Field
          validationRules="required|email_or_phone"
          autoComplete="username"
          onChange={onChange('username')}
          value={username || ''}
          type="email"
          id="email"
          name="email"
          label={
            <>
              <Text text="EMAIL_OR_PHONE" />
              <Tooltip message={Text('SEND_VERIFICATION_INFO')} />
            </>
          }
        />
        <Field
          validationRules="required|min:8"
          autoComplete="new-password"
          onChange={onChange('password')}
          value={password || ''}
          type="password"
          id="password"
          name="password"
          label={
            <>
              <Text text="DESIRED_PASSWORD" />
              <Tooltip message={Text('AUTH_NEW_PASSWORD')} />
            </>
          }
        />
        <Field
          validationRules="required|same:password"
          validationReference={{ password }}
          onChange={onChange('confirmPassword')}
          value={confirmPassword || ''}
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          label={Text('VERIFY_PASSWORD')}
        />
        <TargetingDisclaimer />
        {hasLilyLegacyUsernameConflictException ? (
          <p className={css.errorRed}>
            Antamallasi sähköpostilla on joskus luotu tunnus Lily.fi:hin. Jos haluat
            käyttää tätä sähköpostia, sinun tulee ottaa käyttöön vanha tunnuksesi{' '}
            <Link path={routes.claimOldProfile()}>täällä</Link>.
          </p>
        ) : (
          <Loading
            error={mutationError}
            label={Text('CREATING')}
            loading={mutationLoading}
          />
        )}
        <FormButtons
          submitEnabled={!!username && !!password && !!confirmPassword}
          submitLabel={Text('CREATE_USER')}
        />
        <HaveAccount />
      </Form>
    )
  }
}

export default CreateUserForm
