/**
 * Formats date in form `d.m.yyyy`.
 */
export function formatDate(date: Date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

/**
 * Formats date in form `hh.mm`.
 */
export function formatTime(date: Date) {
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  return `${hours}.${minutes}`
}

/**
 * Formats date in form `d.m.yyyy klo hh.mm`.
 */
export function formatDateAndTime(dateStr: string) {
  const date: Date = new Date(Date.parse(dateStr))
  return `${formatDate(date)} klo ${formatTime(date)}`
}
