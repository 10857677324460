import * as React from 'react'
import { withApollo } from 'react-apollo'
import get from 'lodash/get'
import qs from 'qs'

import Layout from '../layout/Layout'
import Logo from '../components/Logo'
import Title from '../components/Title'
import CreateUserForm from '../forms/CreateUserForm'
import UserConfirmCodeForm from '../forms/UserConfirmCodeForm'
import Footer from '../components/Footer'
import { Text } from '../content/text'
import { setAuthTokens, clearAuthTokens } from '../helpers/authentication'
import {
  getQueryStringParams,
  urlQueryStringRedirect
} from '../helpers/urlQueryString'
import { identity } from '../helpers/identity'
import setSSOIDCookie from '../helpers/setSsoidCookie'
import { FormProps } from '../types/FormProps'
import * as css from '../styles/Form.css'

const enum CreationStage {
  form = 'FORM',
  confirmUser = 'CONFIRMATION_USER',
  userLoggedIn = 'USER_LOGGED_IN'
}

type State = {
  /**
   * `subId` is the subject id received in the response from `createUser`.
   */
  subId: string
  username: string
  password: string
  signUpState: string
  confirmPassword: string
  usernameType: string
  creationStage: CreationStage
  verificationSuccess: boolean
}
interface Props extends FormProps {
  onLoginSuccess?: Function
  client: any
}
class CreateUser extends React.Component<Props, State> {
  static defaultProps = {
    onLoginSuccess: identity
  }

  state = {
    subId: undefined,
    username: '',
    password: '',
    signUpState: undefined,
    confirmPassword: '',
    usernameType: 'email',
    creationStage: CreationStage.form,
    verificationSuccess: false
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
    clearAuthTokens()
    this.setState({
      signUpState: JSON.stringify(getQueryStringParams())
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setFormState = (valueName, isCheckbox = false) => (e) => {
    this.setState({
      [valueName]: isCheckbox ? get(e, 'target.checked') : get(e, 'target.value', e)
    })
  }

  creationCompleted = (result) => {
    const subId = result.data.createUser.userSub
    this.setState({
      subId,
      creationStage: CreationStage.confirmUser
    })
  }

  onVerificationCompleted = (data) => {
    if (get(data, 'confirmUser.success', false)) {
      const loginResult = data.confirmUser.loginResult
      this.setState({
        verificationSuccess: true
      })
      this.logIn(loginResult)
    }
  }

  logIn = (loginResult) => {
    const { history, onLoginSuccess, client } = this.props

    this.setState({
      creationStage: CreationStage.userLoggedIn
    })

    setAuthTokens(loginResult)
    onLoginSuccess(loginResult)
    setSSOIDCookie(client)

    urlQueryStringRedirect(loginResult.refreshToken, history)
  }

  cancel = () => {
    this.setState({
      creationStage: CreationStage.form
    })
  }

  render() {
    const {
      subId,
      username,
      password,
      signUpState,
      confirmPassword,
      usernameType,
      creationStage,
      verificationSuccess
    } = this.state

    const webView =
      qs.parse(location.search, { ignoreQueryPrefix: true }).webview === 'true'

    return (
      <Layout main withTopPadding={webView}>
        {!webView && <Logo />}
        <Layout constrained centered>
          {creationStage === CreationStage.form && (
            <>
              <Title
                title={Text('CREATE_USER')}
                text={Text('CREATE_USERNAME_INFO')}
              />
              <CreateUserForm
                username={username}
                password={password}
                signUpState={signUpState}
                confirmPassword={confirmPassword}
                onChange={this.setFormState}
                onCreationCompleted={this.creationCompleted}
              />
            </>
          )}
          {creationStage === CreationStage.confirmUser && (
            <>
              <Title
                title={Text(
                  usernameType === 'email'
                    ? 'CONFIRM_EMAIL'
                    : usernameType === 'phone_number'
                      ? 'CONFIRM_PHONE'
                      : 'CONFIRM_ACCOUNT'
                )}
                text={Text(
                  usernameType === 'email'
                    ? 'CONFIRM_EMAIL_INGRESS'
                    : usernameType === 'phone_number'
                      ? 'CONFIRM_PHONE_INGRESS'
                      : 'CONFIRM_ACCOUNT_INGRESS'
                )}
              />
              {!verificationSuccess &&
                usernameType !== 'email' && (
                  <p className={css.noticetext}>
                    <Text text={Text('CONFIRM_NOTICE')} />
                  </p>
                )}
              <UserConfirmCodeForm
                username={username}
                subId={subId}
                onCancel={this.cancel}
                onCompleted={this.onVerificationCompleted}
                verified={verificationSuccess}
              />
            </>
          )}
          {creationStage === CreationStage.userLoggedIn && (
            <Title title={Text('WELCOME')} />
          )}
        </Layout>
        {!webView && <Footer />}
      </Layout>
    )
  }
}

export default withApollo<any, any>(CreateUser)
