import qs from 'qs'
import * as React from 'react'

import ConfirmUserWithLink from '../components/ConfirmUserWithLink'
import ConfirmForgotPasswordWithLink from '../components/ConfirmForgotPasswordWithLink'
import Layout from '../layout/Layout'
import Logo from '../components/Logo'

interface Props {
  history: any
}

type State = {
  /**
   * `subId` is the subject id that is used to confirm the user and that must be
   * specified in the url.
   */
  subId: String
  code: String
  type: String
}

class ConfirmUser extends React.Component<Props, State> {
  state = {
    ...getPropsFromQueryParams()
  }

  render() {
    const { history } = this.props
    const { type, code, subId } = this.state
    return (
      <Layout main>
        <Logo />
        <Layout constrained centered>
          {type === 'createUser' ? (
            <ConfirmUserWithLink code={code} subId={subId} history={history} />
          ) : type === 'forgotPassword' ? (
            <ConfirmForgotPasswordWithLink
              code={code}
              subId={subId}
              history={history}
            />
          ) : null}
        </Layout>
      </Layout>
    )
  }
}

export default ConfirmUser

function getPropsFromQueryParams() {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const { koodi: code, subId, type } = queryParams
  return {
    code,
    subId,
    type: type === 'forgot_password' ? 'forgotPassword' : 'createUser'
  }
}
