import gql from 'graphql-tag'
import { observer } from 'mobx-react'
import * as React from 'react'
import { query } from '../apollo/Query'
import Logo from '../components/Logo'
import Footer from '../components/Footer'
import LogoutLink from '../components/LogoutLink'
import Services from '../components/Services'
import Title from '../components/Title'
import { Text } from '../content/text'
import EditCredentialsForm from '../forms/EditCredentialsForm'
import UpdateAttributesConfirmCodeForm from '../forms/UpdateAttributesConfirmCodeForm'
import Layout from '../layout/Layout'
import { AnyFunction } from '../types/Function'
import { Identity } from '../types/Identity'
import ChangePasswordForm from '../forms/ChangePasswordForm'
import DeleteAccount from '../components/DeleteAccount'

import get from 'lodash/get'
import FlashMessage from '../components/FlashMessage'
import * as css from '../styles/Form.css'

export enum CredentialsFormOptions {
  none = 'none',
  email = 'email',
  phone = 'phoneNumber',
  password = 'password'
}

const enum CredentialsStage {
  form = 'FORM',
  confirmUserAttribute = 'CONFIRMATION_USER'
}

type State = {
  attributeValue: string
  changedAttribute: string
  enabledForm: CredentialsFormOptions
  credentialsStage: CredentialsStage
  verificationSuccess: boolean
  flashMessage?: string
}

type Props = {
  history: any
  refetchQuery?: AnyFunction
  queryResult: {
    viewer: Identity
    viewerProfiles: any
  }
}

const getIdentity = gql`
  {
    viewer {
      id
      email
      phoneNumber
    }
    viewerProfiles {
      profileType
      username
    }
  }
`

// The component state gets wiped after refetching content so the flash message needs to be stored outside
let GLOBAL_FLASH_MESSAGE = ''

// The flash message will be shown for the duration of 'showForMs'
// and until the component gets updated after that
const setFlashMessage = (msg: string, showForMs = 4000) => {
  GLOBAL_FLASH_MESSAGE = msg
  setTimeout(() => {
    GLOBAL_FLASH_MESSAGE = ''
  }, showForMs)
}

@query(getIdentity)
@observer
class ManageCredentials extends React.Component<Props, State> {
  state: State = {
    attributeValue: '',
    changedAttribute: '',
    enabledForm: CredentialsFormOptions.none,
    credentialsStage: CredentialsStage.form,
    verificationSuccess: false
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setStage = (which = CredentialsStage.form) => {
    this.setState({
      credentialsStage: which
    })
  }

  setEnabled = (which = CredentialsFormOptions.none) => {
    this.setState({
      enabledForm: which
    })
  }

  onDoneEditCredentials = (whichChanged) => (newValue) => {
    if (!whichChanged) {
      this.onCompleted()
      setFlashMessage(Text('DATA_CHANGE_SUCCESSFUL'))
    } else if (whichChanged === 'password') {
      if (get(newValue, 'changePassword.success', false)) {
        this.onCompleted()
        setFlashMessage(Text('PASSWORD_CHANGE_SUCCESSFUL'))
      }
    } else {
      this.setState({
        attributeValue: whichChanged !== 'password' ? newValue : '',
        changedAttribute: whichChanged,
        credentialsStage: CredentialsStage.confirmUserAttribute
      })
    }
  }

  onCompleted = () => {
    this.reset()
    this.props.refetchQuery()
  }

  onVerificationCompleted = (data) => {
    if (get(data, 'verifyUserAttribute.success', false)) {
      this.setState({
        verificationSuccess: true
      })

      setTimeout(() => {
        if (this._isMounted) {
          this.onCompleted()
        }
      }, 2000)
    }
  }

  reset = () => {
    this.setState({
      credentialsStage: CredentialsStage.form,
      enabledForm: CredentialsFormOptions.none
    })
  }

  render() {
    const {
      queryResult: { viewer, viewerProfiles }
    } = this.props
    const {
      attributeValue,
      changedAttribute,
      enabledForm,
      credentialsStage,
      verificationSuccess
    } = this.state

    return (
      <Layout main>
        <LogoutLink />
        <Logo />
        <Layout constrained>
          {credentialsStage === CredentialsStage.form && (
            <>
              <Title
                title={Text('CREDENTIALS_FORM_TITLE')}
                text={Text('CREDENTIALS_FORM_INGRESS')}
              />
              <EditCredentialsForm
                onEditCompleted={this.onDoneEditCredentials}
                onEnable={this.setEnabled}
                phoneNumber={viewer.phoneNumber}
                email={viewer.email}
                onCancel={this.reset}
                enabledField={enabledForm}
              />
              <ChangePasswordForm
                username={viewer.email ? viewer.email : viewer.phoneNumber}
                onCancel={this.reset}
                onCompleted={this.onDoneEditCredentials('password')}
                onEnable={() => this.setEnabled(CredentialsFormOptions.password)}
                enabled={enabledForm === CredentialsFormOptions.password}
              />
              <FlashMessage message={GLOBAL_FLASH_MESSAGE} />
            </>
          )}

          {credentialsStage === CredentialsStage.confirmUserAttribute && (
            <>
              <Title
                title={Text(
                  changedAttribute === 'email'
                    ? 'CONFIRM_EMAIL'
                    : changedAttribute === 'phone_number'
                      ? 'CONFIRM_PHONE'
                      : 'CONFIRM_ACCOUNT'
                )}
                text={Text('CONFIRM_ACCOUNT_INGRESS')}
              />
              {!verificationSuccess && (
                <p className={css.noticetext}>
                  <Text text={Text('CONFIRM_NOTICE')} />
                </p>
              )}
              <UpdateAttributesConfirmCodeForm
                onCancel={this.reset}
                attributeValue={attributeValue}
                attributeName={changedAttribute}
                onCompleted={this.onVerificationCompleted}
                verified={verificationSuccess}
              />
            </>
          )}
          {credentialsStage === CredentialsStage.form && (
            <>
              <Services
                viewerProfiles={viewerProfiles}
                onProfileDeleteSuccess={this.onCompleted}
              />
              <DeleteAccount />
            </>
          )}
        </Layout>
        <Footer />
      </Layout>
    )
  }
}

export default ManageCredentials
