import * as React from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { Query } from '../apollo/Query'
import Form from './Form'
import Field from './Field'
import FormButtons from './FormButtons'
import NewUser from '../components/NewUser'
import { Text } from '../content/text'
import * as css from '../styles/Form.css'

const canClaimLilyProfileQuery = gql`
  query canClaimLilyProfile($username: String!, $password: String!) {
    canClaimLilyProfile(username: $username, password: $password) {
      success
      errorCode
      errorMessage
    }
  }
`

type State = {
  submitClaimCheck: boolean
  claimError: string
}

class ClaimOldProfileForm extends React.Component<any, State> {
  state = {
    submitClaimCheck: false,
    claimError: null
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onSubmit = (e) => {
    e.preventDefault()

    if (!this.state.submitClaimCheck) {
      this.setState({ submitClaimCheck: true })
    }
  }

  onChange = (valueName) => (e) => {
    const { onChange } = this.props

    if (this.state.submitClaimCheck) {
      this.setState({ submitClaimCheck: false })
    }

    onChange(valueName)(e)
  }

  QueryResponse = (props) => {
    const { onClaimCheckSuccess } = this.props

    if (props.queryResult.canClaimLilyProfile.success) {
      setTimeout(() => {
        if (this._isMounted) {
          onClaimCheckSuccess()
        }
      }, 500)
    }

    return (
      <p style={{ color: 'var(--green)', fontWeight: 'bold' }}>
        <Text text={'DATA_FETCHED'} />
      </p>
    )
  }

  CanClaimError = (error) => {
    const { onClaimCheckSuccess } = this.props

    if (error === 'LilyProfileFullNameRequiredException') {
      // timeout is 1 ms so that we don't get warning about updating during existing state transition and so that the user doesn't have time to see the error message
      setTimeout(() => {
        if (this._isMounted) {
          onClaimCheckSuccess(true)
        }
      }, 1)
    }
  }

  render() {
    const { legacyUsername, legacyPassword } = this.props
    const { submitClaimCheck } = this.state
    return (
      <Form className={css.form} onSubmit={this.onSubmit}>
        <Field
          validationRules="required|email"
          autoComplete="email"
          onChange={this.onChange('legacyUsername')}
          value={legacyUsername || ''}
          type="email"
          id="legacyUsername"
          name="legacyUsername"
          label={Text('EMAIL')}
        />
        <Field
          validationRules="required"
          autoComplete="password"
          onChange={this.onChange('legacyPassword')}
          value={legacyPassword || ''}
          type="password"
          id="legacyPassword"
          name="legacyPassword"
          label={Text('PASSWORD')}
        />
        {submitClaimCheck && (
          <Query
            query={canClaimLilyProfileQuery}
            variables={{ username: legacyUsername, password: legacyPassword }}
            component={this.QueryResponse}
            onError={this.CanClaimError}
          />
        )}
        <FormButtons
          submitEnabled={!!legacyUsername && !!legacyPassword && !submitClaimCheck}
          submitLabel={Text('CONTINUE')}
        />
        <NewUser />
      </Form>
    )
  }
}

export default withApollo(ClaimOldProfileForm)
