import { Provider } from 'mobx-react'
import * as React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import * as Validator from 'validatorjs'
import createClient from './apollo/client'
import { Text } from './content/text'
import { store } from './helpers/authentication'
import { isEmail } from './helpers/isEmail'
import { isPhone } from './helpers/isPhone'
import './index.css'
import Root from './Root'

Validator.register(
  'email_or_phone',
  (value) => isEmail(value) || isPhone(value),
  Text('ERROR_EMAIL_PHONE')
)

Validator.register('age_confirmation_required', (value) => value === true)

Validator.register('phone', isPhone, Text('ERROR_PHONE'))

let client

const init = async () => {
  client = await createClient()
  renderApp(Root)
}

init()

const renderApp = (Component: any) =>
  render(
    <AppContainer>
      <Provider state={store}>
        <Component client={client} />
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  )

if (module.hot) {
  module.hot.accept('./Root', () => {
    const nextRoot = require('./Root').default
    renderApp(nextRoot)
  })
}
