import gql from 'graphql-tag'
import * as get from 'lodash/get'
import { stringify as stringifyQueryString } from 'qs'
import * as React from 'react'
import { mutate } from '../apollo/Mutation'
import Loading from '../components/Loading'
import ResendCodeByCancel from '../components/ResendCodeByCancel'
import Title from '../components/Title'
import { Text } from '../content/text'
import CodeInput from '../forms/CodeInput'
import Field from '../forms/Field'
import Form from '../forms/Form'
import FormButtons from '../forms/FormButtons'
import { routes } from '../routes'
import * as css from '../styles/Form.css'
import { FormProps } from '../types/FormProps'

interface Props extends FormProps {
  username: string
  confirmationDestination: string
}

type State = {
  code: string
  password: string
  verificationSuccess: boolean
}

const forgotPasswordConfirmation = gql`
  mutation confirmForgotPassword(
    $username: String!
    $password: String!
    $code: String!
  ) {
    confirmForgotPassword(username: $username, password: $password, code: $code) {
      success
      errorCode
      errorMessage
      state
    }
  }
`

@mutate(forgotPasswordConfirmation)
class ForgotPasswordConfirmationForm extends React.Component<Props, State> {
  state = {
    code: '',
    password: '',
    confirmPassword: '',
    verificationSuccess: false
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  sendRequest = (e) => {
    e.preventDefault()
    const { code, password } = this.state
    const { mutator, username, history } = this.props

    if (!code || !password) {
      // TODO: Replace with a notification.
      alert('You have to provide your code and a new password.')
      return
    }

    mutator({
      variables: {
        username,
        password,
        code
      }
    }).then(({ data }) => {
      if (get(data, 'confirmForgotPassword.success', false)) {
        this.setState({
          verificationSuccess: true
        })

        setTimeout(() => {
          if (this._isMounted) {
            const stateStr = get(data, 'confirmForgotPassword.state')
            let qs = ''
            if (stateStr) {
              try {
                const stateObj = JSON.parse(stateStr)
                qs = '?' + stringifyQueryString(stateObj)
              } catch (err) {
                console.error(err)
              }
            }
            history.push(`${routes.home()}${qs}`)
          }
        }, 2000)
      }
    })
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  render() {
    const { code, password, confirmPassword, verificationSuccess } = this.state
    const { mutationError, mutationLoading, username } = this.props
    const homePathWithQuery = `${routes.home()}${window.location.search || ''}`

    return (
      <Form onSubmit={this.sendRequest} className={css.form}>
        {!verificationSuccess && (
          <>
            <Field
              validationRules="required|digits:6"
              onChange={this.setFormState('code')}
              value={code}
              type="text"
              id="confirmationCode"
              name="confirmationCode"
              message={<ResendCodeByCancel style={{ marginTop: 'var(--margin)' }} />}
              label={Text('CODE')}>
              <CodeInput style={{ marginLeft: '0' }} />
            </Field>
            <input
              type="hidden"
              name="username"
              autoComplete="username"
              value={username}
            />
            <Field
              validationRules="required|min:8"
              autoComplete="new-password"
              onChange={this.setFormState('password')}
              value={password}
              type="password"
              id="password"
              name="password"
              label={Text('NEW_PASSWORD')}
            />
            <Field
              validationRules="required|same:password"
              validationReference={{ password }}
              onChange={this.setFormState('confirmPassword')}
              value={confirmPassword || ''}
              type="password"
              name="confirmPassword"
              label={Text('VERIFY_NEW_PASSWORD')}
            />
          </>
        )}
        <Loading
          error={mutationError}
          label={Text('CHANGING_PASSWORD')}
          loading={mutationLoading}
        />
        {!verificationSuccess && (
          <FormButtons
            submitEnabled={!!code && !!password && !!confirmPassword}
            cancelRoute={homePathWithQuery}
            submitLabel={Text('SEND')}
          />
        )}
      </Form>
    )
  }
}

export default ForgotPasswordConfirmationForm
