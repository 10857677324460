import classnames from 'classnames'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { identity } from '../helpers/identity'
import { AnyFunction } from '../types/Function'
import * as css from './button.css'

type ButtonProps = {
  children?: React.ReactNode
  label?: string | React.ReactNode
  onClick?: AnyFunction
  type?: string
  to?: string
  href?: string
  className?: string
  style?: any
  inverted?: boolean
  transparent?: boolean
  disabled?: boolean
  action?: boolean
  leftAligned?: boolean
  small?: boolean
  target?: string
}

export default ({
  children,
  label = children,
  onClick = identity,
  type = 'button',
  to = false,
  href = false,
  className,
  style,
  inverted,
  transparent,
  disabled,
  action,
  small,
  leftAligned,
  target
}: ButtonProps) => {
  const buttonClassName = classnames(
    {
      [css.button]: true,
      [css.inverted]: inverted,
      [css.disabled]: disabled,
      [css.actionButton]: action,
      [css.small]: small,
      [css.leftAligned]: leftAligned
    },
    className
  )

  if (to) {
    return (
      <NavLink style={style} to={to} className={buttonClassName}>
        {label}
      </NavLink>
    )
  }

  if (href) {
    return (
      <a style={style} href={href} target={target} className={buttonClassName}>
        {label}
      </a>
    )
  }

  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClassName}>
      {label}
    </button>
  )
}
