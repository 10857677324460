import * as React from 'react'
import Button from '../components/Button'
import Title from '../components/Title'
import Layout from '../layout/Layout'
import * as css from '../styles/Form.css'

type State = {
  requestSent: boolean
}

export default class ForgotUsername extends React.Component {
  state: State = {
    requestSent: false
  }

  sendRequest = (e) => {
    e.preventDefault()

    this.setState({
      requestSent: true
    })
  }

  render() {
    const { requestSent } = this.state

    return (
      <Layout main>
        <Title title="Unohtunut käyttäjätunnus" />

        <form className={css.form} onSubmit={this.sendRequest}>
          {requestSent ? (
            <div>
              <p>
                Olemme lähettäneet käyttäjätunnuksesi ositteeseen:{' '}
                <b>maija.meikalainen@gmail.com</b>
              </p>
            </div>
          ) : (
            <div>
              <p>
                Jos et muista käyttäjätunnustasi, voimme lähettää sen
                rekisteröitymisen yhteydessä antamaasi sähköpostiosoitteeseen.
              </p>

              <div className={css.inputRow}>
                <label htmlFor="curomernr">Asiakasnumero</label>
                <input type="curomernr" id="text" />
              </div>

              <p>
                Asiakasnumerosi löydät lehden takakannesta osoitetietojesi yhteydessä
                tai laskusta. <a>Etkö löydä asiakasnumeroasi?</a>
              </p>

              <div className={css.inputRow}>
                <label htmlFor="zip">Postinumero</label>
                <input type="zip" id="number" />
              </div>

              <div className={css.inputRow}>
                <label htmlFor="surname">Sukunimi</label>
                <input type="surname" id="text" />
              </div>
            </div>
          )}

          <div className={css.buttonRow}>
            {requestSent ? (
              <Button type="link" to="/" inverted>
                Etusivulle
              </Button>
            ) : (
              <Button inverted>Lähetä</Button>
            )}
          </div>
        </form>
      </Layout>
    )
  }
}
