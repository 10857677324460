import * as React from 'react'
import { Text } from '../content/text'
import * as css from './DeleteAccount.css'
import { mutate } from '../apollo/Mutation'
import gql from 'graphql-tag'
import { AnyFunction } from '../types/Function'
import { clearAuthTokens } from '../helpers/authentication'
import Loading from './Loading'
import FormButtons from '../forms/FormButtons'
import { form } from '../styles/Form.css'
import { observer } from 'mobx-react'
import { MutateProps } from '../types/MutateProps'

const deleteAccount = gql`
  mutation deleteAccount {
    deleteCurrentUser
  }
`

@mutate(deleteAccount)
@observer
class DeleteAccount extends React.Component<MutateProps, any> {
  state = {
    showConfirm: false
  }

  onCancel = (e) => {
    e.preventDefault()
    this.setState({
      showConfirm: false
    })
  }

  onConfirm = (e) => {
    e.preventDefault()
    const { mutator } = this.props
    mutator().then(() => clearAuthTokens())
  }

  onClick = (e) => {
    e.preventDefault()
    this.setState({
      showConfirm: true
    })
  }

  render() {
    const { mutationError, mutationLoading } = this.props
    const { showConfirm } = this.state

    return (
      <div>
        <a href="#" onClick={this.onClick} className={css.deleteAccountLink}>
          <Text text="DELETE_ACCOUNT" />
        </a>
        {showConfirm && (
          <form onSubmit={this.onConfirm}>
            <p className={css.deleteMessage}>
              <Text text="DELETE_ACCOUNT_MESSAGE" />
            </p>
            <Loading
              loading={mutationLoading}
              label={Text('DELETING_ACCOUNT')}
              error={mutationError}
            />
            <FormButtons
              cancelLabel={Text('CANCEL')}
              submitLabel={Text('REMOVE')}
              onCancel={this.onCancel}
            />
          </form>
        )}
      </div>
    )
  }
}

export default DeleteAccount
